import React, { Dispatch, SetStateAction, useReducer } from 'react';
import { CpNumber } from '../components/Start/StartTransaction';

//Mostly miscellaneous types / interfaces / enums
//that dont really belong to a specific model
export type StateHandler<T> = React.Dispatch<SetStateAction<T>>;
export type Nullable<T> = T | null;

export type Key<T> = keyof T;

export const ContractStatus = {
  0: 'created',
  1: 'pending',
  2: 'signed',
  3: 'cancelled',
} as const;

export type TContractStatus = (typeof ContractStatus)[Key<
  typeof ContractStatus
>];

export const InvoiceChannel = {
  6: 'none',
  1: 'email',
  2: 'mail',
  3: 'evoice',
} as const;

export type TInvoiceChannel = (typeof InvoiceChannel)[Key<
  typeof InvoiceChannel
>];

export const InvoicingMethod = {
  0: 'test',
  1: 'reporting',
  2: 'invoice',
} as const;

export type TInvoicingMethod = (typeof InvoicingMethod)[Key<
  typeof InvoicingMethod
>];

export const CENT_TO_EUR = 0.01 as const;
export const EV_CHARGING_UNIT = 'kWh' as const;

export type StripeCardDeclineCode =
  | 'generic_decline'
  | 'insufficient_funds'
  | 'lost_card'
  | 'stolen_card'
  | 'card_velocity_exceeded'
  | undefined;
export type StripeCardErrorCode =
  | 'card_declined'
  | 'expired_card'
  | 'incorrect_cvc'
  | 'processing_error'
  | 'incorrect_number'
  | undefined;

export const CountryCodes = {
  FI: 'FI',
  SE: 'SE',
} as const;

export type CountryCode = 'FI' | 'SE';

export const DEFAULT_GUID = '00000000-0000-0000-0000-000000000000' as const;

export declare type ContractPricingOption = 1 | 2 | 3 | 4;

export const ContractPricingOptions = {
  1: 'test',
  2: 'default',
  3: 'monthly',
  4: 'free',
} as const;

//#region generic reducer stuff, specific reducer stuff will be declared with corresponding component

/**
 * `type` is the property of the object you want to mutate
 * `payload` contains the value(s) for the property
 */
export type FormAction<TState extends { [key: string]: {} }> = {
  type: keyof TState | 'all';
  payload: TState[keyof TState] | string | number | TState;
};

/**
 * `type` is the property whose value is to be validated
 * `validator` is the callback used for validating
 * `payload` is the state of the reducer
 */
export type FormValidityAction<TState extends { [key: string]: {} }> = {
  type: keyof TState;
  validator?: Validator<TState>;
  payload: TState;
};

export type ActionObject<TState, TAction> = {
  state: TState;
  set: React.Dispatch<TAction>;
};

/**
 *
 * @param reducer the return value of the `useReducer`-hook
 * @returns an object containing the state and the reducer-function
 */
export function ObjReducer<TState, TAction>(
  reducer: [TState, React.Dispatch<TAction>]
) {
  return { state: reducer[0], set: reducer[1] };
}

export function useObjReducer<TState extends { [key: string]: {} }>(
  func: typeof TReduce,
  initialState: TState
) {
  return ObjReducer(useReducer<Reducer<TState>>(func, initialState));
}

export function useObjValidator<
  TState extends { [key: string]: {} },
  TValidity extends { [key: string]: boolean }
>(func: typeof TValidate, initialState: FormValidityState<TValidity>) {
  return ObjReducer(
    useReducer<ValidityReducer<TState, TValidity>>(func, initialState)
  );
}

/**
 * Introduces type-safety to the useReducer hook
 *
 * `TState` is the state of the reducer, an interface or a type
 */
export type Reducer<TState extends { [key: string]: {} }> = React.Reducer<
  TState,
  FormAction<TState>
>;

/**
 *
 */
export type ValidityReducer<
  TState extends { [key: string]: {} },
  TValidity extends { [key: string]: boolean }
> = React.Reducer<FormValidityState<TValidity>, FormValidityAction<TState>>;

export type FormValidityState<TValidity extends { [key: string]: boolean }> = {
  errors: TValidity;
  isFormValid: boolean;
};

/**
 * The type of the `validator`-function in FormValidityAction
 */
export type Validator<TState extends { [key: string]: {} }> = (
  payload: TState
) => boolean;

/**
 * @param state The state of the reducer
 * @param action the action performed
 * @returns
 */
export function TReduce<TState extends { [key: string]: {} }>(
  state: TState,
  action: FormAction<TState>
): TState {
  return {
    ...state,
    [action.type]: action.payload,
  };
}

export function TValidate<
  TState extends { [key: string]: {} },
  TValidity extends { [key: string]: boolean }
>(state: FormValidityState<TValidity>, action: FormValidityAction<TState>) {
  const stateIsValid = action.validator
    ? action.validator(action.payload)
    : true;
  const ret = {
    ...state,
    ...{
      errors: {
        ...state.errors,
        [action.type]: !stateIsValid,
      },

      isFormValid:
        stateIsValid && checkIfFormIsValid(state, action.type, stateIsValid),
    },
  };
  return ret;
}

function checkIfFormIsValid<
  TValidity extends { [key: string]: boolean },
  TState extends { [key: string]: {} }
>(
  state: FormValidityState<TValidity>,
  currentKey: keyof TState,
  actionValidity: boolean
) {
  if (!actionValidity) return false;
  const keys = Object.keys(state.errors);
  for (const key of keys) {
    if (key === currentKey) continue;
    if (state.errors[key]) return false;
  }
  return true;
}

/**
 * Empties and validates field as valid
 * @param fieldNames
 * @param state
 * @param setState
 * @param setValidity
 */
export function DisableValidationForAndEmptyFields<
  TValidity extends { [key: string]: boolean },
  TState extends { [key: string]: number | string }
>(
  fieldNames: (keyof TState & keyof TValidity)[],
  state: TState,
  setState: Dispatch<FormAction<TState>>,
  setValidity: Dispatch<FormValidityAction<TState>>
) {
  for (const name of fieldNames) {
    setState({
      type: name,
      payload: '',
    });
    setValidity({
      type: name,
      payload: state,
      validator: () => true,
    });
  }
}

/**
 *Empties and invalidates specified fields
 * @param fieldNames
 * @param state
 * @param setState
 * @param setValidity
 */
export function EmptyFields<
  TValidity extends { [key: string]: boolean },
  TState extends { [key: string]: number | string }
>(
  fieldNames: (keyof TState & keyof TValidity)[],
  state: TState,
  setState: Dispatch<FormAction<TState>>,
  setValidity: Dispatch<FormValidityAction<TState>>
) {
  for (const name of fieldNames) {
    setState({
      type: name,
      payload: '',
    });
    setValidity({
      type: name,
      payload: state,
      validator: () => false,
    });
  }
}

export function FillAndValidateFields<
  TValidity extends { [key: string]: boolean },
  TState extends { [key: string]: number | string }
>(
  fieldNames: (keyof TState & keyof TValidity)[],
  fieldValues: (string | number)[],
  state: TState,
  setState: Dispatch<FormAction<TState>>,
  setValidity: Dispatch<FormValidityAction<TState>>
) {
  for (let i = 0; i < fieldNames.length; i++) {
    setState({
      type: fieldNames[i],
      payload: fieldValues[i],
    });
    setValidity({
      type: fieldNames[i],
      payload: state,
      validator: () => DefaultStringValidator(fieldValues[i]),
    });
  }
}

function DefaultStringValidator(s: string | number): boolean {
  return s.toString().trimStart().trimEnd().length > 0;
}

//#endregion

export function separatedCpIdToSingleString(charge_point_id: CpNumber) {
  return charge_point_id.countryCode
    .concat('-')
    .concat(charge_point_id.chargepointNumber);
}

export function pad(s: string | number) {
  if (Number(s) < 10) {
    return `0${s}`;
  }
  return s;
}

/**
 * Returns a copy of the given object with the keys `toExclude` excluded
 * @param obj
 * @param toExclude
 */
export function exclude<T extends { [key: string]: any }>(
  obj: T,
  toExclude: Array<keyof T>
): Partial<T> {
  //Copy
  const ret = { ...obj };
  toExclude.forEach((a) => {
    delete ret[a];
  });
  return ret;
}
