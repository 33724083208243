import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LngDetector, { DetectorOptions } from 'i18next-browser-languagedetector';
import { en, fi, sv } from './resources/langs/export';

const options: DetectorOptions = {
  // order and from where user language should be detected
  order: ['localStorage', 'cookies', 'navigator', 'path'],

  lookupCookie: 'i18next',
  lookupLocalStorage: 'i18nextLng',
  lookupFromPathIndex: 0,
  lookupFromSubdomainIndex: 0,

  // cache user language on
  caches: ['localStorage'],
  excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)
};

i18n
  .use(initReactI18next)
  .use(LngDetector)
  .init({
    detection: options,
    fallbackLng: ['fi'],
    debug: false,
    ns: 'common',
    defaultNS: 'common',
    resources: {
      en,
      fi,
      sv,
    },
    keySeparator: '.',
    supportedLngs: ['sv', 'fi'],
    interpolation: {
      prefix: '{{',
      suffix: '}}',
      formatSeparator: ',',
    },
    lng: localStorage.getItem('currLang') || 'fi',
  });

export default i18n;
