import styled from 'styled-components';

export const StyledStartTransaction = styled.div`
  .spinner {
    animation-name: spin;
    animation-duration: 500ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  .align-left {
    justify-content: left;
    align-items: left;
    text-align: left;
  }
`;
