import { i18n } from 'i18next';
import { useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { NavigateFunction, Outlet, useNavigate, useParams } from 'react-router';
import styled from 'styled-components';

export default function LanguagePath() {
  const { i18n } = useTranslation();
  const { lang } = useParams();
  const navigate = useNavigate();
  const curPath = window.location.pathname;
  useEffect(() => {
    if (i18n.resolvedLanguage !== lang) {
      if (
        i18n.options.supportedLngs &&
        (i18n.options.supportedLngs as readonly string[]).includes(lang)
      ) {
        i18n.changeLanguage(lang);
      }
    }
    navigateAndReplace(navigate, i18n, curPath, lang);
    //eslint-disable-next-line
  }, [lang]);

  return <Outlet />;
}

export function navigateAndReplace(
  navigate: NavigateFunction,
  i18n: i18n,
  curPath: string,
  lang?: string
) {
  navigate(
    '/' +
      i18n.resolvedLanguage +
      curPath.replace(lang ? lang : '', '').replace(/\/{1,}[^A-Za-z]*\/*/, '/'),
    {
      replace: true,
    }
  );
}

const StyledTranslateButton = styled.div`
  .translate-button {
    position: absolute;
    top: 2%;
    right: 5%;
    border: none;
    background: none;
    color: green;
    max-width: 30%;
  }
`;

export function TranslateButton() {
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  return (
    <StyledTranslateButton>
      {i18n.resolvedLanguage === 'fi' ? (
        <Button
          className="translate-button"
          onMouseDown={(e) => {
            e.preventDefault();
            i18n.changeLanguage('sv');
            navigateAndReplace(navigate, i18n, window.location.pathname, 'fi');
          }}
        >
          På svenska
        </Button>
      ) : (
        <Button
          className="translate-button"
          onMouseDown={(e) => {
            e.preventDefault();
            i18n.changeLanguage('fi');
            navigateAndReplace(navigate, i18n, window.location.pathname, 'sv');
          }}
        >
          Suomeksi
        </Button>
      )}
    </StyledTranslateButton>
  );
}
