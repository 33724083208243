import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
  FormControl,
  InputGroup,
  Form,
  DropdownButton,
  Dropdown,
  Row,
} from 'react-bootstrap';
import styled from 'styled-components';
import {
  FormAction,
  FormValidityAction,
  FormValidityState,
} from '../../Utilities/Types';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';

const StyledValidatableField = styled.div`
  .invalid {
    border: 1px solid #ff0000;
  }
  display: flex;
  justify-content: flex-start;
  flex-flow: row wrap;

  .rounded-corners {
    border-bottom-left-radius: 6px !important;
    border-bottom-right-radius: 6px !important;
    border-top-left-radius: 6px !important;
    border-top-right-radius: 6px !important;
  }
`;

const StyledHeaderField = styled.div`
  .wattery-form-label {
    font-weight: bold;
    color: green;
    margin-bottom: 3px;
    letter-spacing: 1px;
  }
`;

declare interface WatteryFieldBaseProps<TState extends { [key: string]: {} }> {
  notes?: string;
  header: string;
  state: TState;
  setState: Dispatch<FormAction<TState>>;
  type?: string;
}

declare interface WatteryStandaloneFieldBaseProps<
  TState extends string | number
> {
  notes?: string;
  header: string;
  state: TState;
  type?: string;
  setState: Dispatch<SetStateAction<TState>>;
}

declare interface VFBaseProps<
  TState extends { [key: string]: {} },
  TValidity extends { [key: string]: boolean }
> extends WatteryFieldBaseProps<TState> {
  validity: FormValidityState<TValidity>;
  setValidity: Dispatch<FormValidityAction<TState>>;
  field: keyof TState & keyof TValidity;
  validator?: (field: any) => boolean;
  restrictor?: (
    previousValue: any,
    value: any
  ) => string | number | TState[keyof TState];
}

declare interface DFProps<TState extends { [key: string]: {} }>
  extends WatteryFieldBaseProps<TState> {
  field: keyof TState;
}

declare interface DDFProps<
  TState extends { [key: string]: {} },
  TDropdownValues extends { [key: string]: {} }
> extends WatteryFieldBaseProps<TState> {
  field: keyof TState;
  template: TDropdownValues;
  title?: string;
  exceptKVPairs?: Partial<TDropdownValues>;
  staticDropdownTitle?: string;
}

declare interface DFSProps<
  TState extends { [key: string]: {} },
  TOther extends { [key: string]: {} }
> extends WatteryFieldBaseProps<TState> {
  otherState: TOther;
  field: keyof TState & keyof TOther;
  useSameValues?: boolean;
}

export function ValidatableField<
  TState extends { [key: string]: string | number },
  TValidity extends { [key: string]: boolean }
>({
  header,
  state,
  setState,
  validity,
  setValidity,
  field,
  validator,
  notes,
  restrictor,
}: VFBaseProps<TState, TValidity>) {
  const [isValidated, setIsValidated] = useState(false);
  const [prevState, setPrevState] = useState('');

  function DefaultStringValidator(s: string | number): boolean {
    return s.toString().trimStart().trimEnd().length > 0;
  }

  function restrict(previousValue: any, value: any) {
    if (restrictor !== undefined) {
      return restrictor(previousValue, value);
    }
    return value;
  }

  function validate(field: any) {
    return validator ? validator(field) : DefaultStringValidator(field);
  }

  return (
    <StyledValidatableField>
      <WatteryValidatedFieldHeader header={header} notes={notes} />
      <InputGroup className="mb-3">
        <FormControl
          data-lpignore="true"
          className={isValidated && validity.errors[field] ? 'invalid' : ''}
          type="text"
          value={state[field] ? state[field] : prevState}
          onChange={(event) => {
            setPrevState(state[field]?.toString());
            setState({
              type: field,
              payload: restrict(state[field], event.target.value),
            });
          }}
          onBlur={() => {
            if (prevState === state[field]) {
              return;
            } else if (state[field]?.toString().length === 0) {
              setIsValidated(false);
            } else {
              setIsValidated(true);
            }
            setValidity({
              payload: state,
              type: field,
              validator: (state) => validate(state[field]),
            });
          }}
        />
      </InputGroup>
    </StyledValidatableField>
  );
}

export function DefaultField<
  TState extends { [key: string]: string | number }
>({ header, state, setState, field, notes, type }: DFProps<TState>) {
  return (
    <StyledValidatableField>
      <WatteryBaseFieldHeader header={header} notes={notes} />
      <InputGroup className="mb-3">
        <FormControl
          data-lpignore="true"
          type={type ? type : 'text'}
          value={state[field]}
          onChange={(event) =>
            setState({
              type: field,
              payload: event.target.value,
            })
          }
        />
      </InputGroup>
    </StyledValidatableField>
  );
}

export function DropdownField<
  TState extends { [key: string]: string | number },
  TDropdownValues extends {
    [key: string]: string | number;
  }
>({
  state,
  header,
  setState,
  field,
  notes,
  title,
  template,
  exceptKVPairs,
  staticDropdownTitle,
}: DDFProps<TState, TDropdownValues>) {
  const { t } = useTranslation('common', { i18n: i18n });

  function dropdownButton() {
    return (
      <Row>
        <DropdownButton
          title={
            staticDropdownTitle
              ? staticDropdownTitle
              : title
              ? title
              : t(`enumerations.${String(field)}.${template[state[field]]}`)
          }
        >
          <>
            {Object.entries(template).map(([k, v], idx) => {
              if (exceptKVPairs && k in exceptKVPairs) return null;

              return (
                <Dropdown.Item
                  as="button"
                  key={idx}
                  onClick={() => {
                    setState({
                      type: field,
                      payload: k,
                    });
                  }}
                >
                  {t(`enumerations.${String(field)}.${v}`)}
                </Dropdown.Item>
              );
            })}
          </>
        </DropdownButton>
      </Row>
    );
  }
  return (
    <StyledValidatableField>
      <WatteryBaseFieldHeader header={header} notes={notes} />
      <div className={notes ? 'mb-3 mt-3' : 'mb-3'}>
        {staticDropdownTitle ? (
          <InputGroup>
            {dropdownButton()}
            <FormControl
              value={t(
                `enumerations.${String(field)}.${template[state[field]]}`
              )}
              readOnly
              disabled
            />
          </InputGroup>
        ) : (
          <>{dropdownButton()}</>
        )}
      </div>
    </StyledValidatableField>
  );
}

export function DefaultStandaloneField<TState extends string | number>({
  header,
  state,
  setState,
  type,
}: WatteryStandaloneFieldBaseProps<TState>) {
  function handleSetState(event) {
    setState(event.target.value as unknown as TState);
  }
  return (
    <StyledValidatableField>
      <label style={{ marginBottom: '3px' }}>{header}</label>
      <InputGroup className="mb-3">
        <FormControl
          name={type}
          className="rounded-corners"
          data-lpignore="true"
          autoComplete={type}
          type={type ? type : 'text'}
          value={state}
          onChange={(event) => handleSetState(event)}
        />
      </InputGroup>
    </StyledValidatableField>
  );
}

export function DefaultSlaveField<
  TState extends { [key: string]: string | number },
  TOther extends { [key: string]: string | number }
>({
  header,
  notes,
  state,
  setState,
  field,
  otherState,
  useSameValues,
  type,
}: DFSProps<TState, TOther>) {
  useEffect(() => {
    if (useSameValues) {
      setState({
        type: field,
        payload: otherState[field],
      });
    }
    //eslint-disable-next-line
  }, [useSameValues]);
  return (
    <StyledValidatableField>
      <WatteryBaseFieldHeader header={header} notes={notes} />
      <InputGroup className="mb-3">
        <FormControl
          data-lpignore="true"
          type={type ? type : 'text'}
          value={state[field]}
          onChange={(event) =>
            setState({
              type: field,
              payload: event.target.value,
            })
          }
        />
      </InputGroup>
    </StyledValidatableField>
  );
}

export function WatteryBaseFieldHeader({
  header,
  notes,
}: {
  header: string;
  notes?: string;
}) {
  return notes ? (
    <StyledHeaderField>
      <label className="wattery-form-label">{header}</label>
      <br />
      <Form.Text>{notes}</Form.Text>
    </StyledHeaderField>
  ) : (
    <StyledHeaderField>
      <label className="wattery-form-label">{header}</label>
    </StyledHeaderField>
  );
}

export function WatteryValidatedFieldHeader({
  header,
  notes,
}: {
  header: string;
  notes?: string;
}) {
  return notes ? (
    <StyledHeaderField>
      <label className="wattery-form-label">{header} (*)</label>
      <br />
      <Form.Text>{notes}</Form.Text>
    </StyledHeaderField>
  ) : (
    <StyledHeaderField>
      <label className="wattery-form-label">{header} (*)</label>
    </StyledHeaderField>
  );
}
