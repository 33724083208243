import { Key } from '../Utilities/Types';
import amex from '../resources/images/amexIcon.png';
import visa from '../resources/images/visaIcon.png';
import mastercard from '../resources/images/masterCardIcon.png';

export type WatteryReceipt = {
  pricingType: 0 | 1;
  price: number;
  startPrice: number;
  kWh: number;
  totalPrice: number;
  subTransactions: SubTransaction[];
  seller: string;
  address?: string;
  businessId: string;
  vat: number;
  currency: string;
  receiptNumber: string;
  duration: number;
  startTime: string;
  endTime: string;
  reportingSiteId: number;
  idTag: string;
  country?: string;
  city?: string;
  zip?: string;
  reportingSite?: string;
  invoicingFee?: number;
  invoiceStatus?: number;
  serviceFee: number;
  transactionFee: number;
  cardInfo: CardInfo;
  serviceFeeVat: number;
};

export declare interface CardInfo {
  last4?: string;
  brand?: string;
}

export const Card = {
  visa: { alt: 'Visa', src: visa },
  amex: { alt: 'American Express', src: amex },
  mastercard: { alt: 'Mastercard', src: mastercard },
} as const;

export type CardType = (typeof Card)[Key<typeof Card>];

export declare interface SubTransaction {
  id: number;
  transactionId: number;
  startTime: string;
  endTime: string;
  kWh: number;
  intervalPrice: number;
  price: Price;
  calculatedPrice: number;
  calculatedMargin: number;
}

export declare interface Price {
  unitPrice: number;
  currency: string;
  vatRate: number;
  biddingArea?: string;
  margin?: number;
  periodStart?: string;
  periodEnd?: string;
}
