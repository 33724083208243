import { ThemeProvider } from 'styled-components';
import './App.css';
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
  RouterProvider,
} from 'react-router-dom';
import { GlobalStyles } from './global';
import { LightTheme } from './theme';
import { useState } from 'react';
import WatteryOrderForm from './components/OrderForm/WatteryOrderForm';
import RexelOrderForm from './components/OrderForm/RexelOrderForm';
import ThankYou from './components/OrderForm/ThankYou';
import StartTransaction from './components/Start/StartTransaction';
import ActiveTransactions from './components/Active/ActiveTransactions';
import Receipt from './components/Payment/Receipt';
import TermsOfService from './components/ToS/TermsOfService';
import ErrorPage from './components/ErrorPage/ErrorPage';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LanguagePath from './components/General/Language';

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<LanguagePath />}>
      <Route path="" element={<StartTransaction />} />
      <Route path="id/:charge_point_id" element={<StartTransaction />} />
      <Route path="num/:charge_point_number" element={<StartTransaction />} />
      <Route path="order" element={<WatteryOrderForm />} />
      <Route path="order/rexel" element={<RexelOrderForm />} />
      <Route path="order/confirmation" element={<ThankYou />} />
      <Route path="active/:unique_id" element={<ActiveTransactions />} />
      <Route path="receipt/:unique_id" element={<Receipt />} />
      <Route path="terms-of-service" element={<TermsOfService />} />
      <Route path="active" element={<ActiveTransactions />} />
      <Route path="*" element={<ErrorPage />} />
      <Route path=":lang">
        <Route path="" element={<StartTransaction />} />
        <Route path="id/:charge_point_id" element={<StartTransaction />} />
        <Route path="num/:charge_point_number" element={<StartTransaction />} />
        <Route path="order" element={<WatteryOrderForm />} />
        <Route path="order/rexel" element={<RexelOrderForm />} />
        <Route path="order/confirmation" element={<ThankYou />} />
        <Route path="active/:unique_id" element={<ActiveTransactions />} />
        <Route path="receipt/:unique_id" element={<Receipt />} />
        <Route path="terms-of-service" element={<TermsOfService />} />
        <Route path="active" element={<ActiveTransactions />} />
        <Route path="*" element={<ErrorPage />} />
      </Route>
    </Route>
  )
);

function App() {
  const [theme] = useState(() => new LightTheme());
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles theme={theme} />

      <ToastContainer
        draggable
        pauseOnFocusLoss
        pauseOnHover
        position="top-center"
      />
      <RouterProvider router={router} />
    </ThemeProvider>
  );
}

export default App;
