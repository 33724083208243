import { Dispatch } from 'react';
import { Contract } from '../../Model/Contract';
import { ContractPricingOptions } from '../../Utilities/Types';
import {
  Dropdown,
  DropdownButton,
  Form,
  FormCheck,
  InputGroup,
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';
import {
  CountryCodes,
  FormAction,
  FormValidityAction,
  FormValidityState,
  InvoiceChannel,
  InvoicingMethod,
  Key,
  StateHandler,
  exclude,
} from '../../Utilities/Types';
import {
  emailRegex,
  isNotDigitOrComma,
  rationalNumberRegex,
} from '../../Utilities/Regex';
import {
  DefaultField,
  DropdownField,
  ValidatableField,
  WatteryBaseFieldHeader,
} from '../General/ValidatableField';
import { ContractValidity, OrderValidity } from './WatteryOrderForm';
import WatteryOrderFormOrder from './WatteryOrderFormOrder';
import { Order } from '../../Model/Order';
import { DynamicListForm } from '../General/DynamicListForm';

declare interface WOFCProps {
  contract: Contract;
  setContract: Dispatch<FormAction<Contract>>;
  contractValidity: FormValidityState<ContractValidity>;
  setContractValidity: Dispatch<FormValidityAction<Contract>>;
  isDifferentFromContract: boolean;
  setIsDifferentFromContract: StateHandler<boolean>;
  order: Order;
  setOrder: Dispatch<FormAction<Order>>;
  oValidity: FormValidityState<OrderValidity>;
  setOValidity: Dispatch<FormValidityAction<Order>>;
  reportReceivers: string[];
  setReportReceivers: StateHandler<string[]>;
}

const relevantPricingOptions = exclude(ContractPricingOptions, [1, 4]);

const WatteryOrderFormContract = ({
  contract,
  setContract,
  contractValidity,
  setContractValidity,
  isDifferentFromContract,
  setIsDifferentFromContract,
  order,
  setOrder,
  oValidity,
  setOValidity,
  reportReceivers,
  setReportReceivers,
}: WOFCProps) => {
  const { t } = useTranslation('common', { i18n: i18n });

  function ValidateEmail(email: string) {
    return emailRegex.test(email);
  }

  return (
    <>
      <ValidatableField
        header={t('components.orderForm.form.contract.name')}
        state={contract}
        setState={setContract}
        validity={contractValidity}
        setValidity={setContractValidity}
        field="CompanyName"
      />
      <ValidatableField
        header={t('components.orderForm.form.shared.businessId')}
        state={contract}
        setState={setContract}
        validity={contractValidity}
        setValidity={setContractValidity}
        field="BusinessId"
      />
      <ValidatableField
        header={t('components.orderForm.form.shared.saddress2')}
        state={contract}
        setState={setContract}
        validity={contractValidity}
        setValidity={setContractValidity}
        field="StreetAddress"
      />
      <DefaultField
        header={t('components.orderForm.form.shared.saddress1')}
        state={contract}
        setState={setContract}
        field="AddressSpecifier"
      />

      <ValidatableField
        header={t('components.orderForm.form.shared.zip')}
        state={contract}
        setState={setContract}
        validity={contractValidity}
        setValidity={setContractValidity}
        field="ZipCode"
      />
      <ValidatableField
        header={t('components.orderForm.form.shared.city')}
        state={contract}
        setState={setContract}
        validity={contractValidity}
        setValidity={setContractValidity}
        field="City"
      />
      <DropdownField
        header={t('components.orderForm.form.shared.country')}
        state={contract}
        setState={setContract}
        field="Country"
        template={CountryCodes}
      />
      <>
        <WatteryBaseFieldHeader
          header={t('enumerations.invoiceChannel.enumName')}
        />
        <div className="mb-3">
          <DropdownButton
            title={t(
              `enumerations.invoiceChannel.${InvoiceChannel[
                contract.InvoiceChannel
              ]!}`
            )}
          >
            <>
              {Object.entries(InvoiceChannel).map(([k, v], idx) => {
                if (v === 'none' || v === 'mail') return null;
                return (
                  <Dropdown.Item
                    as="button"
                    key={idx}
                    onClick={() => {
                      setContract({
                        type: 'InvoiceChannel',
                        payload: Number(k) as Key<typeof InvoiceChannel>,
                      });
                    }}
                  >
                    {t(`enumerations.invoiceChannel.${v}`)}
                  </Dropdown.Item>
                );
              })}
            </>
          </DropdownButton>
        </div>
        {contract.InvoiceChannel === 3 ? (
          <>
            <ValidatableField
              header={t('components.orderForm.form.contract.operator')}
              state={contract}
              setState={setContract}
              validity={contractValidity}
              setValidity={setContractValidity}
              field="EinvoiceOperator"
            />
            <ValidatableField
              header={t('components.orderForm.form.contract.einvoice')}
              state={contract}
              setState={setContract}
              validity={contractValidity}
              setValidity={setContractValidity}
              field="EinvoiceAddress"
            />
            <DefaultField
              header={t('components.orderForm.form.contract.edi')}
              state={contract}
              setState={setContract}
              field="EinvoiceEdi"
            />
          </>
        ) : contract.InvoiceChannel === 1 ? (
          <>
            <ValidatableField
              header={t('components.orderForm.form.contract.invEmail')}
              state={contract}
              setState={setContract}
              validity={contractValidity}
              setValidity={setContractValidity}
              field="InvoiceEmail"
              validator={ValidateEmail}
            />
          </>
        ) : null}
        <DefaultField
          header={t('components.orderForm.form.order.invoiceReference')}
          state={contract}
          setState={setContract}
          field="InvoiceReference"
        />
        <Form.Check
          className="mb-3"
          type="checkbox"
          checked={isDifferentFromContract}
          onChange={() => setIsDifferentFromContract(!isDifferentFromContract)}
          label={
            <WatteryBaseFieldHeader
              header={t('components.orderForm.form.additional.fill.button')}
            />
          }
        />
        {isDifferentFromContract ? (
          <>
            <h5>{t('components.orderForm.form.order.header')}</h5>
            <WatteryOrderFormOrder
              order={order}
              setOrder={setOrder}
              oValidity={oValidity}
              setOValidity={setOValidity}
            />
          </>
        ) : null}
      </>
      <br />
      <h5>{t('components.orderForm.form.pricing.header')}</h5>
      <Form.Text className="mb-3">
        {t('components.orderForm.form.pricing.description')}
      </Form.Text>
      <br />
      <div className="mt-3">
        {Object.entries(relevantPricingOptions).map(([k, v], idx) => {
          return (
            <FormCheck
              className="mb-3"
              key={idx}
              type="radio"
              value={contract.ContractPricingOptionId}
              label={t(`enumerations.ContractPricingOptionId.${v}`)}
              checked={Number(k) === contract.ContractPricingOptionId}
              onChange={() =>
                setContract({
                  type: 'ContractPricingOptionId',
                  payload: Number(k),
                })
              }
            ></FormCheck>
          );
        })}
      </div>
      <br />
      <h5>{t('components.orderForm.form.additional.header')}</h5>
      <Form.Text className="mb-3">
        {t('components.orderForm.form.additional.description')}
      </Form.Text>
      <br />
      <Form.Text className="mb-3">
        {t('components.orderForm.form.additional.option')}
      </Form.Text>
      <br />
      <br />
      <Form.Text className="mb-3">
        {t('components.orderForm.form.additional.observe')}
      </Form.Text>
      <br />
      <br />
      <InputGroup className="mb-3">
        <DropdownButton
          title={t(
            `components.orderForm.form.additional.${InvoicingMethod[
              contract.InvoicingMethod
            ]!}`
          )}
        >
          <>
            {Object.entries(InvoicingMethod).map(([k, v], idx) => {
              if (v === 'test') return null;
              return (
                <Dropdown.Item
                  as="button"
                  key={idx}
                  onClick={() => {
                    setContract({
                      type: 'InvoicingMethod',
                      payload: Number(k) as Key<typeof InvoicingMethod>,
                    });
                  }}
                >
                  {t(`components.orderForm.form.additional.${v}`)}
                </Dropdown.Item>
              );
            })}
          </>
        </DropdownButton>
      </InputGroup>

      {contract.InvoicingMethod === 2 ? (
        <>
          <ValidatableField
            header={t('components.orderForm.form.contract.vat')}
            state={contract}
            setState={setContract}
            validity={contractValidity}
            setValidity={setContractValidity}
            field="Vat"
            validator={(field: string) => {
              const ret = rationalNumberRegex.test(field);
              let real: string | undefined | null = null;
              if (ret) {
                const match = field.toString().match(rationalNumberRegex);
                real = match?.[0].replace(',', '.');
                contract['Vat'] = Number(real);
              }
              return ret && Number(real) >= 0 && Number(real) < 100;
            }}
            restrictor={(previousValue: string, value: string) => {
              if (isNotDigitOrComma.test(value)) {
                return previousValue;
              }
              return value;
            }}
          />
          <ValidatableField
            header={t('components.orderForm.form.contract.bank')}
            state={contract}
            setState={setContract}
            validity={contractValidity}
            setValidity={setContractValidity}
            field="BankAccount"
          />
          <DefaultField
            header={t('components.orderForm.form.contract.paymentRef')}
            state={contract}
            setState={setContract}
            field="PaymentReference"
          />
          <DynamicListForm
            header={t('components.orderForm.form.contract.reports')}
            array={reportReceivers}
            setArray={setReportReceivers}
          />
        </>
      ) : null}
      <br />

      <h5>{t('components.orderForm.form.order.serviceHeader')}</h5>
      <ValidatableField
        header={t('components.orderForm.form.order.managerName')}
        state={contract}
        setState={setContract}
        validity={contractValidity}
        setValidity={setContractValidity}
        field="AdminName"
      />
      <ValidatableField
        header={t('components.orderForm.form.order.managerEmail')}
        state={contract}
        setState={setContract}
        validity={contractValidity}
        setValidity={setContractValidity}
        field="AdminEmail"
        validator={ValidateEmail}
      />
      <ValidatableField
        header={t('components.orderForm.form.contract.defaultPrice', {
          currency: contract.Country === 'FI' ? '€' : 'kr',
        })}
        state={contract}
        setState={setContract}
        validity={contractValidity}
        setValidity={setContractValidity}
        field="DefaultChargepointPrice"
        validator={(field: string) => {
          const ret = rationalNumberRegex.test(field);
          let real: string | undefined | null = null;
          if (ret) {
            const match = field.toString().match(rationalNumberRegex);
            real = match?.[0].replace(',', '.');
            contract['DefaultChargepointPrice'] = Number(real);
          }
          return ret && Number(real) >= 0;
        }}
        restrictor={(previousValue: string, value: string) => {
          if (isNotDigitOrComma.test(value)) {
            return previousValue;
          }
          return value;
        }}
      />
      <br />
      <br />
      <DefaultField
        header={t('components.orderForm.form.order.discount')}
        state={order}
        setState={setOrder}
        field="DiscountCode"
      />
      <DefaultField
        header={t('components.orderForm.form.order.remarks')}
        state={order}
        setState={setOrder}
        field="Notes"
      />
      <ValidatableField
        header={t('components.orderForm.form.order.respondentName')}
        state={order}
        setState={setOrder}
        validity={oValidity}
        setValidity={setOValidity}
        field="RespondentName"
      />
      <ValidatableField
        header={t('components.orderForm.form.order.respondentEmail')}
        state={order}
        setState={setOrder}
        validity={oValidity}
        setValidity={setOValidity}
        field="RespondentEmail"
        validator={ValidateEmail}
      />
    </>
  );
};

export default WatteryOrderFormContract;
