import { Container } from 'react-bootstrap';
import WatteryLogo from '../WatteryLogo/WatteryLogo';

declare interface ErrorPageProps {
  message?: string | React.ReactNode;
}

export default function ErrorPage({ message }: ErrorPageProps) {
  return (
    <Container
      id="component-margin"
      className="container-min-height-min-content align-left"
    >
      <div style={{ textAlign: 'left' }}>
        <WatteryLogo className="wattery-logo" />
      </div>
      <div
        style={{
          position: 'absolute',
          textAlign: 'left',
          top: '30%',
          transform: 'translate(0%, -100%)',
        }}
      >
        {message ? <h4>{message}</h4> : <h4>Page not found</h4>}
      </div>
    </Container>
  );
}
