import axios from 'axios';
import {
  WatteryResponse,
  WatteryResponseData,
} from '../components/wattery-shared/General/Option';

export async function postRequest<
  TResponse,
  TData extends { [key: string]: any }
>(path: string, data: TData): Promise<WatteryResponse<TResponse>> {
  try {
    const response = await axios.post<WatteryResponseData<TResponse>>(
      path,
      data
    );
    return new WatteryResponse(response.data, true);
  } catch (e: any) {
    return new WatteryResponse(e.response.data, false);
  }
}

export async function getRequest<TResponse>(
  path: string
): Promise<WatteryResponse<TResponse>> {
  try {
    const response = await axios.get<WatteryResponseData<TResponse>>(path);
    return new WatteryResponse(response.data, true);
  } catch (e: any) {
    return new WatteryResponse(e.response.data, false);
  }
}
