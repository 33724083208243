import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { ActiveTransaction, SpotPrice } from '../../Model/Transaction';
import {
  chargePointIsConnected,
  checkCompletedTransactions,
  getCurrentSpotPriceForCp,
  getPersonalActiveTransaction,
  remoteStopTransaction,
} from '../../Services/Transactions';
import { Button, Card, Col, Row } from 'react-bootstrap';
import logo from '../../resources/images/WatteryLogoGreen.svg';
import { StyledActiveTransactions } from './ActiveTransactions.styled';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';
//import { addUserInvoice } from '../../Services/Stripe';
import refreshIcon from '../../resources/images/refreshIcon.svg';
import loadingIcon from '../../resources/images/loadingIcon.svg';
import { format } from 'date-fns';
import { DEFAULT_GUID, pad } from '../../Utilities/Types';
import { CurrencyMap } from '../../Utilities/CurrencyMap';
import {
  getServiceFeeAmount,
  toLocalePrice,
} from '../../Utilities/UtilityFunctions';
import { PricingType } from '../../Model/Chargepoint';
import { toast } from 'react-toastify';
import { TranslateButton } from '../General/Language';

const defaultTransaction: ActiveTransaction = {
  firstName: 'No name',
  lastName: 'No name',
  idTag: 'No tag',
  name: 'No match',
  status: 'Not charging',
  nickname: 'No match',
  timestamp: 'Not started',
  duration: '00:00',
  connectorId: 0,
  reservationId: null,
  chargePointId: 'No match',
  current: 0,
  transactionUniqueId: DEFAULT_GUID,
  kWh: 0,
  totalCost: 0,
  address: 'No match',
  currency: '',
  power: 0,
  sellerName: 'No match',
  chargePointNumber: -1,
  pricingType: PricingType.FIXED,
  biddingArea: 'No match',
  margin: 0,
};

export default function ActiveTransactions() {
  const at_id = useParams<{ unique_id?: string }>().unique_id;

  const [transaction, setTransaction] =
    useState<ActiveTransaction>(defaultTransaction);
  const [showView, setShowView] = useState(0);
  const [start, setStart] = useState('');
  const [refreshing, setRefreshing] = useState(false);
  const [spotPrice, setSpotPrice] = useState<SpotPrice>();

  const navigate = useNavigate();
  //let ignore = useRef<boolean>(false);
  useEffect(() => {
    async function getData() {
      if (at_id === undefined) {
        setShowView(-2);
        return;
      }

      const res = await getPersonalActiveTransaction(at_id);
      if (res.isSuccess()) {
        const t = res.response;
        setTransaction(t);
        setStart(format(new Date(t.timestamp), 'yyyy-MM-dd HH:mm:ss'));
        if (t.pricingType === PricingType.SPOT) {
          const spot = await getCurrentSpotPriceForCp(t.chargePointId);
          if (spot.isSuccess()) setSpotPrice(spot.response);
        }
        setShowView(1);
      } else {
        setShowView(-1);
        const completedExists = await checkCompletedTransactions(at_id);
        if (completedExists.isSuccess()) {
          return navigate(`/receipt/${at_id}`);
        }
      }
    }
    getData();
    //eslint-disable-next-line
  }, []);

  /* useEffect(() => {
    async function getData() {
      if (
        !ignore.current &&
        (transaction.userInvoiceId === undefined ||
          transaction.userInvoiceId === null)
      ) {
        const uId = await addUserInvoice(transaction);
        if (uId.isSuccess()) {
          ignore.current = true;
          setTransaction(() => {
            return { ...transaction, userInvoiceId: uId.response };
          });
        }
      } else {
        setTransaction(transaction);
      }
    }
    if (transaction.transactionUniqueId !== DEFAULT_GUID) {
      getData();
    }
  }, [transaction]); */

  async function refresh() {
    if (at_id !== undefined) {
      setRefreshing(true);
      const res = await getPersonalActiveTransaction(at_id);
      if (res.isSuccess()) {
        const t = res.response;
        setTransaction(t);
        if (t.pricingType === PricingType.SPOT) {
          const spot = await getCurrentSpotPriceForCp(t.chargePointId);
          if (spot.isSuccess()) setSpotPrice(spot.response);
        }

        setShowView(1);
      } else {
        const completedExists = await checkCompletedTransactions(at_id);
        if (completedExists.isSuccess()) {
          return navigate(`/receipt/${at_id}`);
        }
        setShowView(-1);
      }
    } else {
      setShowView(-1);
    }
    setRefreshing(false);
  }

  const { t } = useTranslation('common', { i18n: i18n });

  return (
    <StyledActiveTransactions>
      {showView === 0 ? (
        <h2 className="align-self-center">{t('global.view.loading')}</h2>
      ) : showView === -1 ? (
        <h2 className="align-self-center">{t('global.view.error')}</h2>
      ) : (
        <>
          <TranslateButton />
          <div className="logo-container">
            <img src={logo} alt="logo" width={256} />
          </div>
          <RenderTransaction
            refresh={refresh}
            transaction={transaction}
            unique_id={at_id!}
            start={start}
            refreshing={refreshing}
            spotPrice={spotPrice?.price}
          />
        </>
      )}
    </StyledActiveTransactions>
  );
}

declare interface RenderTransactionProps {
  transaction: ActiveTransaction;
  unique_id: string;
  refresh: () => Promise<void>;
  start: string;
  refreshing: boolean;
  spotPrice?: number;
}

function RenderTransaction({
  transaction,
  unique_id,
  refresh,
  start,
  refreshing,
  spotPrice,
}: RenderTransactionProps) {
  const { t } = useTranslation('common', { i18n: i18n });
  const navigate = useNavigate();
  const [stopping, setStopping] = useState(false);
  const [waitForRedirect, setWaitForRedirect] = useState(false);
  const [timeLeft, setTimeLeft] = useState(0);

  useEffect(() => {
    if (!waitForRedirect) return;
    if (timeLeft === 0) {
      return;
    }

    // save intervalId to clear the interval when the
    // component re-renders
    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    // clear interval on re-render to avoid memory leaks
    return () => clearInterval(intervalId);
    // add timeLeft as a dependency to re-rerun the effect
    // when we update it
    //eslint-disable-next-line
  }, [timeLeft]);

  async function handleRemoteStopTransaction() {
    if (!transaction) return;
    if (transaction.transactionUniqueId === DEFAULT_GUID) return;
    const connectedCheck = await chargePointIsConnected(
      transaction.chargePointNumber
    );
    if (!connectedCheck.isSuccess() || connectedCheck.response === 0) {
      toast.error(t('global.alert.failure.remoteStop.notConnected'));
      //notConnectedAlert.activate();
      return;
    }
    setStopping(true);
    const rstRes = await remoteStopTransaction(unique_id, {
      header: 'remotestoptransaction',
      charge_point_id: transaction.chargePointId,
      waitforstop: true,
    });
    if (!rstRes.isSuccess()) {
      /* const resType = rstRes.onError().resultType;
      if (resType === 5 || resType === 2) {
        toast.error(t('global.alert.failure.remoteStop.notAuthorized'));
        //notAuthorizedAlert.activate();
        setStopping(false);
        return;
      } */
      toast.error(t('global.alert.failure.generic'));
      setStopping(false);
      return;
    }
    setWaitForRedirect(true);
    setTimeLeft(10);

    //Needs to be changed
    setTimeout(async () => {
      navigate(`/receipt/${unique_id}`);
    }, 10000);
  }

  function formatDuration(s: string) {
    const [hh, mm] = s.split(':', 2);
    return `${pad(hh)}:${pad(mm)}`;
  }

  function formatPricing(trans: ActiveTransaction) {
    const c = CurrencyMap[trans.currency];

    const tPrice = toLocalePrice(trans.price ? trans.price : 0);
    const tStartPrice = toLocalePrice(trans.startPrice ? trans.startPrice : 0);
    const serviceFee = toLocalePrice(getServiceFeeAmount(transaction.currency));

    if (trans.pricingType === PricingType.FIXED) {
      return trans.startPrice !== undefined && trans.startPrice > 0
        ? `${serviceFee} ${c} + ${tStartPrice} ${c} + ${tPrice} ${c}/kWh`
        : `${serviceFee} ${c} + ${tPrice} ${c}/kWh`;
    } else {
      const margin =
        trans.margin > 0 ? `${toLocalePrice(trans.margin, 3)} ${c}/kWh + ` : '';
      const spotPriceText = t(
        'components.activeTransaction.spotPrice'
      ).toLowerCase();
      const spotPriceNow = `(${t('components.activeTransaction.spotPriceNow', {
        spotPrice: toLocalePrice(spotPrice!, 2),
      })}
     c/kWh) `;
      return `${serviceFee} ${c} + ${margin} ${spotPriceText} ${spotPriceNow}`;
    }
    //  });
  }

  return (
    <>
      <Card className="active-card">
        <Card.Header className="active-card-header">
          <div style={{ position: 'relative' }}>
            <p className="active-card-header-name">{transaction.name}</p>
            <h4
              style={{
                fontWeight: 'bold',
              }}
            >
              {t(`components.activeTransaction.status.${transaction.status}`)}
            </h4>
            <div className="active-card-header-values">
              <p
                style={{
                  fontSize: '1.3rem',
                }}
              >
                {transaction.kWh} kWh
              </p>
              {transaction.pricingType === 0 ? (
                <p
                  style={{
                    fontSize: '1.3rem',
                  }}
                >{`${toLocalePrice(
                  getServiceFeeAmount(transaction.currency) +
                    Number(transaction.totalCost)
                )} ${CurrencyMap[transaction.currency]}`}</p>
              ) : null}
            </div>
          </div>
        </Card.Header>
        <Card.Body style={{ textAlign: 'center' }}>
          <Row className="mb-3">
            <Col>
              {refreshing ? (
                <Button variant="outline-ligth">
                  <img
                    className="spinner"
                    src={loadingIcon}
                    alt="Loading"
                    style={{ width: 30 }}
                  />
                </Button>
              ) : (
                <Button variant="outline-ligth">
                  <img src={refreshIcon} alt="Refresh" onClick={refresh} />
                </Button>
              )}
            </Col>
            <Col>
              <Button
                disabled={transaction === undefined}
                variant="danger"
                size="sm"
                className="active-btn-stop"
                onClick={() => handleRemoteStopTransaction()}
              >
                {stopping ? (
                  <img className="spinner" src={loadingIcon} alt="Loading" />
                ) : (
                  t('global.buttons.stop')
                )}
              </Button>
            </Col>
          </Row>
          <table className="active-card-body">
            <tbody>
              <tr>
                <td width={'35%'}>
                  {t('components.activeTransaction.startTime')}
                </td>
                <td width={'60%'}>{start}</td>
              </tr>
              <tr>
                <td width={'35%'}>
                  {t('components.activeTransaction.duration')}
                </td>
                <td width={'60%'}>{formatDuration(transaction.duration!)}</td>
              </tr>
              <tr>
                <td width={'35%'}>{t('components.activeTransaction.power')}</td>
                <td width={'60%'}>{transaction.power}</td>
              </tr>
              <tr>
                <td width={'35%'}>
                  {t('components.activeTransaction.current')}
                </td>
                <td width={'60%'}>{transaction.current}</td>
              </tr>
              <tr>
                <td width={'35%'}>{t('components.startTransaction.seller')}</td>
                <td width={'60%'}>{transaction.sellerName}</td>
              </tr>
              <tr>
                <td width={'35%'}>
                  {t('components.activeTransaction.chargepoint')}
                </td>
                <td width={'60%'}>
                  {`${transaction.countryCode}-${transaction.chargePointNumber}-${transaction.connectorId}`}
                </td>
              </tr>
              <tr>
                <td width={'35%'}>
                  {t('components.activeTransaction.pricing')}
                </td>
                <td width={'60%'}>{formatPricing(transaction)}</td>
              </tr>
            </tbody>
          </table>
        </Card.Body>
      </Card>
      {waitForRedirect ? (
        <div>{t('components.activeTransaction.redirect', { n: timeLeft })}</div>
      ) : null}
    </>
  );
}
