export const WatteryError = {
  /** Problem communicating with chargepoints */
  COMMUNICATION: 'communication_error',

  /** Default error when sending a PermissionDeniedResult */
  PERMISSION_DENIED: 'permission_denied_error',

  /** Required member IdTag missing */
  TAG: 'tag_error',

  /** Required member CustomerId missing */
  CUSTOMER: 'customer_error',

  /** Required member ConfirmationTokenId missing */
  CONFIRMATION_TOKEN: 'confirmation_token_error',

  /** Required member currency missing */
  CURRENCY: 'currency_error',

  /** Request to OCPP-server rejected */
  REJECTED: 'rejected_error',

  /** Request to OCPP-server blocked */
  BLOCKED: 'blocked_error',

  /** One or more Payment elements sent to Stripe were invalid */
  PAYMENT_PROCESSING: 'payment_processing_error',

  /** Generic server error not to be handled by user */
  SERVER: 'server_error',
} as const;
