import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import i18n from '../i18n';

//please please please change idk this can't possibly be even close to the best solution
export default function useLanguage() {
  let { language } = useParams();

  function onLanguageuageUpdated(lng?: string) {
    const isNotLng = lng === ':language' || !lng;
    const lang = isNotLng ? 'en' : lng;
    i18n.changeLanguage(lang);
    updatePath(lang);
    return false;
  }

  function updatePath(lng: string) {
    const reg = /en|fi|:language/;
    let thing2 = window.location.href.replace(reg, lng);
    if (thing2 !== window.location.href) window.location.href = thing2;
  }

  useEffect(() => {
    onLanguageuageUpdated(language);
    //eslint-disable-next-line
  }, [language]);
}

const getWindowDimensions = () => {
  const { innerWidth: windowWidth, innerHeight: windowHeight } = window;

  return {
    windowWidth,
    windowHeight,
  };
};

export const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions());
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
};
