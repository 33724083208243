export declare interface Chargepoint {
  chargePointId: string;
  price: number;
  publicPrice: number;
  publicStartPrice: number;
  currency: string;
  sellerName: string;
  businessId: string;
  nickname: string;
  address: string;
  vat: number;
  connectorId: number;
  pricingType: number;
  margin: number;
}

export enum AccessType {
  FREE = 0,
  PRIVATE = 1,
  PRIVATE_AND_PUBLIC = 3,
  PUBLIC = 3,
}

export enum PricingType {
  FIXED = 0,
  SPOT = 1,
}

export function formatChargepointPrice(
  selectedChargepoint: Chargepoint,
  header: string,
  serviceFeeText: string,
  startPriceText: string,
  priceText: string,
  spotPriceText: string,
  marginText: string
) {
  const startPrice = Number(
    selectedChargepoint.publicStartPrice > 0 &&
      selectedChargepoint.pricingType === PricingType.FIXED
  )
    ? startPriceText + ' + '
    : selectedChargepoint.pricingType === PricingType.SPOT &&
      selectedChargepoint.margin > 0
    ? marginText
    : '';
  const price =
    selectedChargepoint.pricingType === PricingType.FIXED
      ? priceText
      : spotPriceText;
  return `${header}: ${serviceFeeText} + ${startPrice}${price}`;
}
