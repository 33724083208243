import { WatteryResponse } from '../components/wattery-shared/General/Option';
import axios from 'axios';
import {
  CustomerCreateData,
  PaymentConfirmData,
  PaymentData,
  PaymentIntentCreateData,
  StripeCustomer,
} from '../Model/Stripe';
import { NonUserTag } from '../Model/NonUserTag';
import { WatteryReceipt } from '../Model/Receipt';
import { ActiveTransaction, UserInvoice } from '../Model/Transaction';
import { PaymentIntent } from '@stripe/stripe-js';
import { postRequest } from './ServiceFunctionTemplate';

const defaultRoute = `/api/v1/payments` as const;

export async function createStripeCustomer(
  data: CustomerCreateData
): Promise<WatteryResponse<StripeCustomer>> {
  return postRequest<StripeCustomer, typeof data>(
    `${defaultRoute}/create-customer`,
    data
  );
}

export async function createPaymentIntent(
  data: PaymentIntentCreateData
): Promise<WatteryResponse<string>> {
  try {
    const response = await axios.post(
      `${defaultRoute}/create-payment-intent`,
      data
    );
    return new WatteryResponse(response.data, true);
  } catch (e: any) {
    return new WatteryResponse(e.response.data, false);
  }
}

export const createNonUser = async (
  email?: string
): Promise<WatteryResponse<NonUserTag>> => {
  return await postRequest<NonUserTag, { email?: string }>(
    `${defaultRoute}/create-tag`,
    {
      email: email,
    }
  );
};

export const retrieveCustomer = async (
  idTag: string
): Promise<WatteryResponse<StripeCustomer>> => {
  try {
    const response = await axios.get(
      `${defaultRoute}/retrieve-customer/${idTag}`
    );
    return new WatteryResponse(response.data, true);
  } catch (e: any) {
    return new WatteryResponse(e.response.data, false);
  }
};

export const retrievePaymentIntent = async (
  idTag: string
): Promise<WatteryResponse<PaymentIntent>> => {
  try {
    const response = await axios.get(
      `${defaultRoute}/retrieve-payment-intent/${idTag}`
    );
    return new WatteryResponse(response.data, true);
  } catch (e: any) {
    return new WatteryResponse(e.response.data, false);
  }
};

export const capturePayment = async (
  data: PaymentConfirmData
): Promise<WatteryResponse<string>> => {
  try {
    const response = await axios.post(`${defaultRoute}/capture-payment`, data);
    return new WatteryResponse(response.data, true);
  } catch (e: any) {
    return new WatteryResponse(e.response.data, false);
  }
};

export const getReceipt = async (
  transaction_id: string
): Promise<WatteryResponse<WatteryReceipt>> => {
  try {
    const response = await axios.get(
      `${defaultRoute}/receipt/${transaction_id}`
    );
    return new WatteryResponse(response.data, true);
  } catch (e: any) {
    return new WatteryResponse(e.response.data, false);
  }
};

export const addUserInvoice = async (
  data: ActiveTransaction
): Promise<WatteryResponse<number>> => {
  try {
    const response = await axios.post(`${defaultRoute}/receipt/add`, data);
    return new WatteryResponse(response.data, true);
  } catch (e: any) {
    return new WatteryResponse(e.response.data, false);
  }
};

export const updateUserInvoice = async (
  data: UserInvoice,
  transaction_id: string
): Promise<WatteryResponse<any>> => {
  try {
    const response = await axios.post(
      `${defaultRoute}/receipt/update/${transaction_id}`,
      data
    );
    return new WatteryResponse(response.data, true);
  } catch (e: any) {
    return new WatteryResponse(e.response.data, false);
  }
};

export const createPaymentData = async (
  data: PaymentData
): Promise<WatteryResponse<PaymentData>> => {
  return await postRequest<PaymentData, PaymentData>(
    `${defaultRoute}/create-payment-data`,
    data
  );
};
