import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { StyledWatteryOrderForm } from './WatteryOrderForm.styled';
import {
  TReduce,
  Reducer,
  TValidate,
  ValidityReducer,
  FormValidityState,
  DisableValidationForAndEmptyFields,
  EmptyFields,
} from '../../Utilities/Types';
import { useEffect, useReducer, useState } from 'react';
import { addOrderAndContract } from '../../Services/Order';
import { Contract } from '../../Model/Contract';
import { Order } from '../../Model/Order';
import WatteryOrderFormContract from './WatteryOrderFormContract';
import { WatteryValidatedFieldHeader } from '../General/ValidatableField';
import { useNavigate } from 'react-router-dom';
import WatteryLogo from '../WatteryLogo/WatteryLogo';
import { dismissToasts, showToast } from '../../Utilities/UtilityFunctions';
import { TranslateButton } from '../General/Language';

const InitialContract: Contract = {
  CompanyName: '',
  OrderId: 0,
  BusinessId: '',
  AddressSpecifier: '',
  StreetAddress: '',
  ZipCode: '',
  City: '',
  Country: 'FI',
  InvoicingMethod: 2,
  BankAccount: '',
  PaymentReference: '',
  ReportReceivers: '',
  InvoiceChannel: 3,
  EinvoiceAddress: '',
  EinvoiceOperator: '',
  EinvoiceEdi: '',
  InvoiceEmail: '',
  AdminName: '',
  AdminEmail: '',
  ContractPricingOptionId: 2,
  Notes: '',
  InvoiceReference: '',
  DefaultChargepointPrice: 0.15,
};

const InitialOrder: Order = {
  CompanyName: '',
  BusinessId: '',
  AddressSpecifier: '',
  StreetAddress: '',
  ZipCode: '',
  City: '',
  Country: 'FI',
  InvoiceChannel: 3,
  EinvoiceAddress: '',
  EinvoiceEdi: '',
  EinvoiceOperator: '',
  InvoiceEmail: '',
  InvoiceReference: '',
  DiscountCode: '',
  Notes: '',
  RespondentName: '',
  RespondentEmail: '',
  InvoiceStatus: 0,
};

export type OrderValidity = {
  CompanyName: boolean;
  BusinessId: boolean;
  AddressSpecifier: boolean;
  StreetAddress: boolean;
  ZipCode: boolean;
  City: boolean;
  Country: boolean;
  InvoiceChannel: boolean;
  EinvoiceAddress: boolean;
  EinvoiceOperator: boolean;
  InvoiceEmail: boolean;
  InvoiceReference: boolean;
  DiscountCode: boolean;
  Notes: boolean;
  RespondentName: boolean;
  RespondentEmail: boolean;
};

export type ContractValidity = {
  CompanyName: boolean;
  BusinessId: boolean;
  AddressSpecifier: boolean;
  StreetAddress: boolean;
  ZipCode: boolean;
  City: boolean;
  Country: boolean;
  Vat: boolean;
  BankAccount: boolean;
  PaymentReference: boolean;
  ReportReceivers: boolean;
  EinvoiceAddress: boolean;
  EinvoiceOperator: boolean;
  AdminName: boolean;
  AdminEmail: boolean;
  InvoiceEmail: boolean;
  ContractPricingOption: boolean;
  Notes: boolean;
  InvoiceReference: boolean;
  DefaultChargepointPrice: boolean;
};

const IContractValidity: FormValidityState<ContractValidity> = {
  errors: {
    CompanyName: true,
    BusinessId: true,
    AddressSpecifier: false,
    StreetAddress: true,
    ZipCode: true,
    City: true,
    Country: false,
    Vat: true,
    BankAccount: false,
    PaymentReference: false,
    ReportReceivers: false,
    EinvoiceAddress: true,
    EinvoiceOperator: true,
    InvoiceEmail: false,
    AdminName: true,
    AdminEmail: true,
    ContractPricingOption: false,
    Notes: false,
    InvoiceReference: false,
    DefaultChargepointPrice: false,
  },
  isFormValid: false,
};

const IOrderValidity: FormValidityState<OrderValidity> = {
  errors: {
    CompanyName: true,
    BusinessId: true,
    AddressSpecifier: false,
    StreetAddress: true,
    ZipCode: true,
    City: true,
    Country: false,
    InvoiceChannel: false,
    EinvoiceAddress: true,
    EinvoiceOperator: true,
    InvoiceEmail: false,
    InvoiceReference: false,
    DiscountCode: false,
    Notes: false,
    RespondentName: true,
    RespondentEmail: true,
  },
  isFormValid: false,
};

export type PrimaryManager = {
  Name: string;
  Email: string;
};

export type PrimaryManagerValidity = {
  Name: boolean;
  Email: boolean;
};

declare interface WatteryOrderFormProps {
  logo?: string;
}
const WatteryOrderForm = ({ logo }: WatteryOrderFormProps) => {
  const { t } = useTranslation('common', { i18n: i18n });
  const [isDifferentFromContract, setIsDifferentFromContract] = useState(false);

  const [contract, setContract] = useReducer<Reducer<Contract>>(
    TReduce,
    InitialContract
  );

  const [cValidity, setCValidity] = useReducer<
    ValidityReducer<Contract, ContractValidity>
  >(TValidate, IContractValidity);

  const [order, setOrder] = useReducer<Reducer<Order>>(TReduce, InitialOrder);

  const [oValidity, setOValidity] = useReducer<
    ValidityReducer<Order, OrderValidity>
  >(TValidate, IOrderValidity);

  const [termsAccepted, setTermsAccepted] = useState(false);
  const [confirmed, setConfirmed] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);

  const [reportReceivers, setReportReceivers] = useState<string[]>([]);

  const navigate = useNavigate();

  useEffect(() => {
    if (contract.InvoiceChannel === 1) {
      DisableValidationForAndEmptyFields(
        ['EinvoiceOperator', 'EinvoiceAddress'],
        contract,
        setContract,
        setCValidity
      );
      EmptyFields(['InvoiceEmail'], contract, setContract, setCValidity);
    }
    if (contract.InvoiceChannel === 3) {
      DisableValidationForAndEmptyFields(
        ['InvoiceEmail'],
        contract,
        setContract,
        setCValidity
      );
      EmptyFields(
        ['EinvoiceOperator', 'EinvoiceAddress'],
        contract,
        setContract,
        setCValidity
      );
      setContract({
        type: 'EinvoiceEdi',
        payload: '',
      });
    }

    //eslint-disable-next-line
  }, [contract.InvoiceChannel]);

  useEffect(() => {
    if (contract.InvoicingMethod !== 2) {
      DisableValidationForAndEmptyFields(
        ['Vat', 'BankAccount', 'PaymentReference'],
        contract,
        setContract,
        setCValidity
      );
      setReportReceivers(['']);
    }
    //eslint-disable-next-line
  }, [contract.InvoicingMethod]);

  useEffect(() => {
    if (order.InvoiceChannel === 1) {
      DisableValidationForAndEmptyFields(
        ['EinvoiceOperator', 'EinvoiceAddress'],
        order,
        setOrder,
        setOValidity
      );
      EmptyFields(['InvoiceEmail'], order, setOrder, setOValidity);
    }
    if (order.InvoiceChannel === 3) {
      DisableValidationForAndEmptyFields(
        ['InvoiceEmail'],
        order,
        setOrder,
        setOValidity
      );
      EmptyFields(
        ['EinvoiceOperator', 'EinvoiceAddress'],
        order,
        setOrder,
        setOValidity
      );
      setOrder({
        type: 'EinvoiceEdi',
        payload: '',
      });
    }
    //eslint-disable-next-line
  }, [order.InvoiceChannel]);

  async function submit() {
    dismissToasts();
    setDisableSubmit(true);

    const orderSend: Order = !isDifferentFromContract
      ? {
          CompanyName: contract.CompanyName,
          BusinessId: contract.BusinessId,
          AddressSpecifier: contract.AddressSpecifier,
          StreetAddress: contract.StreetAddress,
          ZipCode: contract.ZipCode,
          City: contract.City,
          Country: contract.Country,
          InvoiceChannel: contract.InvoiceChannel,
          EinvoiceAddress: contract.EinvoiceAddress,
          EinvoiceEdi: contract.EinvoiceEdi,
          EinvoiceOperator: contract.EinvoiceOperator,
          InvoiceEmail: contract.InvoiceEmail,
          InvoiceReference: contract.InvoiceReference,
          DiscountCode: order.DiscountCode,
          Notes: order.Notes,
          RespondentName: order.RespondentName,
          RespondentEmail: order.RespondentEmail,
          InvoiceStatus: 0,
        }
      : order;

    const c = {
      ...contract,
      Vat: Number(contract.Vat),
      ReportReceivers: reportReceivers.toString(),
    };
    const res = await addOrderAndContract({
      order: orderSend,
      contract: c,
    });

    if (!res.success) {
      return showToast(t, 'error', 'generic');
    }

    return navigate('confirmation', { state: 'new-order' });
  }

  //useLanguage();

  return (
    <>
      <StyledWatteryOrderForm className="top-level-component">
        <Container
          id="component-margin"
          className="container-min-height-min-content"
        >
          <TranslateButton />
          <WatteryLogo className="wattery-logo" />
          {logo ? (
            <>
              <Form.Text>In collaboration with</Form.Text>
              <br />
              <br />
              <img
                className="mb-3 collaborator-logo"
                src={logo}
                alt="Collaborator"
              />
            </>
          ) : null}
          <br />
          <br />
          <div className="wattery-form-body">
            <h2 className="mb-3">{t('components.orderForm.body.header')}</h2>
            <h4 className="mb-3">{t('components.orderForm.body.subHeader')}</h4>
            <p className="mb-3">
              {t('components.orderForm.body.preamble.formDescription')}
            </p>
            <p className="mb-3">
              {t('components.orderForm.body.preamble.agreementDescription')}
            </p>
            <table className="mb-3">
              <thead>
                <tr>
                  <th className="mb-3">
                    {t('components.orderForm.body.serviceProvider.header')}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{t('components.orderForm.body.serviceProvider.name')}</td>
                </tr>
                <tr>
                  <td>
                    {t('components.orderForm.body.serviceProvider.orgNumber')}
                  </td>
                </tr>
                <tr>
                  <td>
                    {t('components.orderForm.body.serviceProvider.address')}
                  </td>
                </tr>
                <tr>
                  <td>{t('components.orderForm.body.serviceProvider.city')}</td>
                </tr>
              </tbody>
            </table>
            <table className="mb-3">
              <thead>
                <tr>
                  <th className="mb-3">
                    {t('components.orderForm.body.contact')}:
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{t('components.orderForm.body.contactName')}</td>
                </tr>
                <tr>
                  <td>{t('components.orderForm.body.contactPhone')}</td>
                </tr>
              </tbody>
            </table>
            <h4 className="mb-3">
              {t('components.orderForm.body.validity.header')}
            </h4>
            <p className="mb-3">
              {t('components.orderForm.body.validity.validityDescription')}
            </p>
            <h4 className="mb-3">
              {t('components.orderForm.body.links.header')}
            </h4>
            <table className="mb-3">
              <tbody>
                <tr>
                  <td>
                    <a
                      href={t(
                        'components.orderForm.body.links.serviceDescriptionLink'
                      )}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t('components.orderForm.body.links.serviceDescription')}
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>
                    <a
                      href={t('components.orderForm.body.links.pricingLink')}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t('components.orderForm.body.links.pricing')}
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>
                    <a
                      href={t(
                        'components.orderForm.body.links.generalTermsLink'
                      )}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t('components.orderForm.body.links.generalTerms')}
                    </a>
                  </td>
                </tr>
                <tr>
                  <td>
                    <a
                      href={t(
                        'components.orderForm.body.links.serviceTermsLink'
                      )}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {t('components.orderForm.body.links.serviceTerms')}
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
            <br />
            <hr className="hr" />
            <br />
            <Form.Text className="mb-3">
              {t('components.orderForm.form.required')} *
            </Form.Text>
            <br />
            <br />
            <h4 className="mb-3">{t('components.orderForm.form.header')}</h4>
            <Form.Text className="mb-3">
              {t('components.orderForm.form.description')}
            </Form.Text>
            <br />
            <br />

            <h5>{t('components.orderForm.form.contract.header')}</h5>
            <WatteryOrderFormContract
              contract={contract}
              setContract={setContract}
              contractValidity={cValidity}
              setContractValidity={setCValidity}
              isDifferentFromContract={isDifferentFromContract}
              setIsDifferentFromContract={setIsDifferentFromContract}
              order={order}
              setOrder={setOrder}
              oValidity={oValidity}
              setOValidity={setOValidity}
              reportReceivers={reportReceivers}
              setReportReceivers={setReportReceivers}
            />
            <br />
            <div className="mb-3">
              <Form.Check // prettier-ignore
                type={'checkbox'}
                checked={termsAccepted}
                onChange={() => {
                  setTermsAccepted(!termsAccepted);
                }}
                id={'terms-accepted'}
                label={
                  <WatteryValidatedFieldHeader
                    header={t('components.orderForm.form.additional.terms')}
                  />
                }
              />
              <Form.Check // prettier-ignore
                type={'checkbox'}
                checked={confirmed}
                onChange={() => {
                  setConfirmed(!confirmed);
                }}
                id={'confirmed'}
                label={
                  <WatteryValidatedFieldHeader
                    header={t('components.orderForm.form.additional.confirm')}
                  />
                }
              />
            </div>
            <Row className="mb-3">
              <Col>
                <Button
                  onClick={submit}
                  disabled={
                    !cValidity.isFormValid ||
                    !(termsAccepted && confirmed) ||
                    oValidity.errors.RespondentName ||
                    oValidity.errors.RespondentEmail ||
                    (isDifferentFromContract && !oValidity.isFormValid) ||
                    disableSubmit
                  }
                >
                  {t('global.buttons.submit')}
                </Button>
              </Col>
            </Row>
          </div>
        </Container>
      </StyledWatteryOrderForm>
    </>
  );
};

export default WatteryOrderForm;
