import styled from 'styled-components';

export const StyledReceipt = styled.div`
  font-size: 1rem;
  overflow-y: hidden;

  .row {
    width: 50vw;
  }
  @media only screen and (min-width: 1000px) {
    width: 30vw;
  }
`;
