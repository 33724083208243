import { CSSProperties, useState } from 'react';

declare interface TRProps<T extends React.ReactNode> {
  header?: string | React.ReactNode;
  dataFormatter?: (data: T, extra?: any) => string;
  styling?: 'default' | 'none' | 'custom' | 'append';
  headerStyle?: CSSProperties;
  dataStyle?: CSSProperties;
  rowStyle?: CSSProperties;
  dColSpan?: number;
  hColSpan?: number;
  headerType?: 'bold' | 'data';
  data?: T[] | T;
}

export function TableRow<T extends React.ReactNode>({
  header,
  dataFormatter,
  styling = 'default',
  headerStyle,
  dataStyle,
  rowStyle,
  dColSpan,
  hColSpan,
  headerType = 'bold',
  data,
}: TRProps<T>) {
  const [formatter] = useState(() =>
    dataFormatter ? dataFormatter : (data?: T) => data
  );
  const [internalStyle] = useState<{
    r: CSSProperties | undefined;
    h: CSSProperties | undefined;
    d: CSSProperties | undefined;
  }>(() => {
    switch (styling) {
      default:
        return {
          r: undefined,
          h: {
            textAlign: 'left',
          },
          d: {
            textAlign: 'right',
          },
        };
      case 'none':
        return {
          r: undefined,
          h: undefined,
          d: undefined,
        };
      case 'custom':
        return {
          r: rowStyle,
          h: headerStyle,
          d: dataStyle,
        };
      case 'default':
        return {
          r: undefined,
          h: {
            textAlign: 'left',
          },
          d: {
            textAlign: 'right',
          },
        };
      case 'append':
        return {
          r: rowStyle,
          h: {
            ...headerStyle,
            textAlign: 'left',
          },
          d: {
            ...dataStyle,
            textAlign: 'right',
          },
        };
    }
  });

  return (
    <>
      <tr style={internalStyle.r}>
        {header ? (
          headerType && headerType === 'bold' ? (
            <th colSpan={hColSpan} style={internalStyle.h}>
              {header}
            </th>
          ) : (
            <td colSpan={hColSpan} style={internalStyle.h}>
              {header}
            </td>
          )
        ) : null}
        {data !== undefined ? (
          Array.isArray(data) ? (
            (data as T[]).map((a, i) => (
              <td key={i} colSpan={dColSpan} style={internalStyle.d}>
                {formatter(a)}
              </td>
            ))
          ) : (
            <td colSpan={dColSpan} style={internalStyle.d}>
              {formatter(data as T)}
            </td>
          )
        ) : null}
      </tr>
    </>
  );
}
