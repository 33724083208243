import styled from 'styled-components';

export const StyledSpotPriceWidget = styled.div`
  .widget-header {
    font-size: 14px;
    font-weight: bold;
  }

  .empty-button {
    background: none;
    border: none;
  }

  .empty-button:hover {
    text-decoration: underline;
  }
`;
