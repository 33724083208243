import {
  ActiveTransaction,
  CompletedTransactionUpdate,
  Receipt,
  SpotPrice,
  StartTransactionRequest,
  StopTransactionRequest,
  WatteryOcppResponse,
} from '../Model/Transaction';
import { Chargepoint } from '../Model/Chargepoint';
import { WatteryResponse } from '../components/wattery-shared/General/Option';
import { CpNumber } from '../components/Start/StartTransaction';
import { getRequest, postRequest } from './ServiceFunctionTemplate';

export const getPersonalActiveTransaction = async (
  unique_id: string
): Promise<WatteryResponse<ActiveTransaction>> => {
  return await getRequest(`/api/v1/active/${unique_id}`);
};

export const getCpNumberFromId = async (
  charge_point_id: string
): Promise<WatteryResponse<CpNumber>> => {
  return await getRequest(`/api/v1/chargepoint/id/${charge_point_id}`);
};

export const getChargepointByNumber = async (
  charge_point_number: CpNumber
): Promise<WatteryResponse<Chargepoint>> => {
  return await postRequest(`/api/v1/chargepoint/get/`, charge_point_number);
};

export const remoteStartTransaction = async (
  request: StartTransactionRequest
): Promise<WatteryResponse<WatteryOcppResponse<string>>> => {
  return await postRequest(`/api/v1/transaction/start`, request);
};

export const remoteStopTransaction = async (
  uuid: string,
  request: StopTransactionRequest
): Promise<WatteryResponse<WatteryOcppResponse<string>>> => {
  return await postRequest(`/api/v1/transaction/stop/${uuid}`, request);
};

export const getCompletedTransaction = async (
  id: string
): Promise<WatteryResponse<Receipt>> => {
  return await getRequest(`/api/v1/transaction/completed/${id}`);
};

export const updateAndGetCompletedTransaction = async (
  data: CompletedTransactionUpdate
): Promise<WatteryResponse<Receipt>> => {
  return await postRequest(`/api/v1/payments/receipt/update-get-ct`, data);
};

export const chargePointIsConnected = async (
  chargepoint_number: number
): Promise<WatteryResponse<number>> => {
  return await getRequest(
    `/api/v1/chargepoint/connected/${chargepoint_number}`
  );
};

export const checkCompletedTransactions = async (
  transaction_id: string
): Promise<WatteryResponse<boolean>> => {
  return await getRequest(`/api/v1/payments/receipt/exists/${transaction_id}`);
};

export const getCpSpotprice = async (
  charge_point_id: string
): Promise<WatteryResponse<SpotPrice[]>> => {
  return await getRequest(
    `api/v1/chargepoint/spotprice/upcoming/${charge_point_id}`
  );
};

export const getCurrentSpotPriceForCp = async (
  charge_point_id: string
): Promise<WatteryResponse<SpotPrice>> => {
  return await getRequest(
    `api/v1/chargepoint/spotprice/current/${charge_point_id}`
  );
};
