import { useEffect, useReducer, useState } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';
import { CpIdInput } from './CpIdInput';
import refreshIconAnimated from '../../resources/images/refreshIconAnimated.gif';
import {
  CountryCode,
  CountryCodes,
  FormAction,
  FormValidityState,
  Reducer,
  TValidate,
  ValidityReducer,
  separatedCpIdToSingleString,
} from '../../Utilities/Types';
import React from 'react';
import {
  getChargepointByNumber,
  getCpNumberFromId,
} from '../../Services/Transactions';
import InjectedCheckoutForm from '../Payment/Payment';
import { Chargepoint, formatChargepointPrice } from '../../Model/Chargepoint';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';
import { StyledStartTransaction } from './StartTransaction.styled';
import { Elements } from '@stripe/react-stripe-js';
import {
  loadStripe,
  Stripe,
  StripeElementLocale,
  StripeElementsOptions,
} from '@stripe/stripe-js';
import { STRIPE_API_KEY } from '../../';
import { useParams } from 'react-router';
import WatteryLogo from '../WatteryLogo/WatteryLogo';
import { SpotPriceWidget } from '../SpotPrice/SpotPriceWidget';
import { CurrencyMap } from '../../Utilities/CurrencyMap';
import {
  getServiceFeeAmount,
  resolveStripeAmount,
  toLocalePrice,
} from '../../Utilities/UtilityFunctions';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { TranslateButton } from '../General/Language';

export declare type CpNumber = {
  countryCode: CountryCode;
  chargepointNumber: string;
  connectorId: string;
};

const InitialCpId: CpNumber = {
  countryCode: 'FI',
  chargepointNumber: '',
  connectorId: '',
};

declare type CpValidity = {
  contryCode: boolean;
  chargepointNumber: boolean;
  connectorId: boolean;
};

const InitialValidity: FormValidityState<CpValidity> = {
  errors: {
    contryCode: false,
    chargepointNumber: true,
    connectorId: true,
  },
  isFormValid: false,
};

function CpIdReduce(state: CpNumber, action: FormAction<CpNumber>): CpNumber {
  if (action.type === 'all') {
    if (typeof action.payload === 'object') {
      const s = action.payload as unknown as CpNumber;
      return {
        countryCode: s.countryCode,
        connectorId: s.connectorId.toString(),
        chargepointNumber: s.chargepointNumber.toString(),
      };
    }
  }
  if (typeof action.payload !== 'string') return state;
  switch (action.type) {
    case 'countryCode':
      return {
        ...state,
        countryCode: action.payload as CountryCode,
      };
    case 'chargepointNumber':
      return {
        ...state,
        chargepointNumber: action.payload,
      };
    case 'connectorId':
      return {
        ...state,
        connectorId: action.payload,
      };
    default:
      return state;
  }
}

//Allows for checking wider scope of values in an array
//such as src/Types[CountryCodes] <- Object.values(CountryCodes)
declare global {
  interface ReadonlyArray<T> {
    includes<U>(x: U & (T & U extends never ? never : unknown)): boolean;
  }
  interface Array<T> {
    includes<U>(x: U & (T & U extends never ? never : unknown)): boolean;
  }
}

function parseCpNumberParam(param?: string): CpNumber {
  if (!param) {
    return InitialCpId;
  }
  if (!param.includes('-')) {
    return parseCpNumberParamNoDashes(param);
  }
  const components = getCpNumberComponentsFromParam(param, true);
  if (components === null) {
    return InitialCpId;
  }
  const num = {
    countryCode: components.cc as unknown as CountryCode,
    chargepointNumber: components.cn,
    connectorId: components.ci,
  };
  return num;
}

function parseCpNumberParamNoDashes(param: string): CpNumber {
  const components = getCpNumberComponentsFromParam(param);
  if (components === null) {
    return InitialCpId;
  }
  const num = {
    countryCode: components.cc as unknown as CountryCode,
    chargepointNumber: components.cn,
    connectorId: components.ci,
  };
  return num;
}

function getCpNumberComponentsFromParam(
  param: string,
  dashes?: boolean
): { cc: string; cn: string; ci: string } | null {
  if (!dashes) {
    const indeces = { cc_b: 2, cn_a: 2, cn_b: 7, ci: 7 };
    const cc = param.slice(0, indeces.cc_b);
    const cn = param.slice(indeces.cn_a, indeces.cn_b);
    const ci = param.slice(indeces.ci);
    return isParamWellFormed(cc, cn, ci) ? { cc, cn, ci } : null;
  }
  const [cc, cn, ci] = param.split('-');
  return isParamWellFormed(cc, cn, ci) ? { cc, cn, ci } : null;
}

function isParamWellFormed(cc: string, cn: string, ci: string) {
  if (!Object.values(CountryCodes).includes(cc) || cc.length !== 2) {
    return false;
  }
  return (
    !(isNaN(Number(cn)) && isNaN(Number(ci))) ||
    cn.length === 5 ||
    ci.length === 1
  );
}

const StartTransaction = (): React.ReactNode => {
  const [selectedChargepoint, setSelectedChargepoint] = useState<Chargepoint>();
  const [cpId, setCpId] = useReducer<Reducer<CpNumber>>(
    CpIdReduce,
    parseCpNumberParam(
      useParams<{ charge_point_number?: string }>().charge_point_number
    )
  );
  const [searching, setSearching] = useState(false);
  const [cpValidate, validateCp] = useReducer<
    ValidityReducer<CpNumber, CpValidity>
  >(TValidate, InitialValidity);

  const id = useParams<{ charge_point_id?: string }>().charge_point_id;

  const [stripe, setStripe] = useState<Stripe | null>(null);
  const [stripeOptions, setStripeOptions] = useState<StripeElementsOptions>();
  const [currCurrency, setCurrCurrency] = useState('eur');
  const [wait, setWait] = useState(false);
  const [backgroundTasks, setBackgroundTasks] = useState(false);
  const [fetched, setFetched] = useState(false);

  useEffect(() => {
    async function getData() {
      if (id === undefined) return;
      const cpNum = await getCpNumberFromId(id);
      if (!cpNum.isSuccess()) return;
      setCpId({ payload: cpNum.response, type: 'all' });
      setFetched(true);
    }
    getData();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    let ignore = false;
    async function g() {
      await setupStripe(currCurrency);
    }
    if (!ignore) g();
    return () => {
      ignore = true;
    };
    //eslint-disable-next-line
  }, []);

  async function setupStripe(currency: string) {
    const options: StripeElementsOptions = {
      mode: 'payment',
      currency: currency,
      paymentMethodCreation: 'manual',
      capture_method: 'manual',
      setup_future_usage: 'off_session',
      amount: resolveStripeAmount(currency),
      locale: i18n.language as StripeElementLocale,
      appearance: {
        variables: {
          fontFamily: `Nunito, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, 
            Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji,Segoe UI Symbol`,
        },
      },
    };
    const stripeTemp = await loadStripe(STRIPE_API_KEY);
    setStripeOptions(options);

    setStripe(stripeTemp);
  }

  //useLanguage();

  useEffect(() => {
    async function getData() {
      setSearching(true);
      const res = await getChargepointByNumber(cpId);

      if (res.isSuccess()) {
        setSearching(false);
        const temp = res.response;
        if (temp.connectorId !== Number(cpId.connectorId)) {
          setCpId({
            type: 'connectorId',
            payload: `${temp.connectorId}`,
          });
        }
        setSelectedChargepoint(temp);
        setFetched(false);
        const tempCurr = temp.currency.toLowerCase();
        if (tempCurr !== currCurrency) {
          setCurrCurrency(tempCurr);
          setupStripe(tempCurr);
        }
      } else {
        setSearching(false);
        toast.error(
          t('global.alert.failure.noAccess.chargepoint', {
            charge_point_id: separatedCpIdToSingleString(cpId),
          })
        );

        setSelectedChargepoint(undefined);
        validateCp({
          type: 'chargepointNumber',
          payload: InitialCpId,
          validator: () => false,
        });
      }
    }
    if (cpValidate.isFormValid) getData();
    else setSelectedChargepoint(undefined);
    //eslint-disable-next-line
  }, [
    cpValidate.isFormValid,
    cpId.countryCode,
    cpValidate.errors.chargepointNumber,
  ]);

  const { t } = useTranslation('common', { i18n: i18n });
  return (
    <>
      <StyledStartTransaction className="top-level-component">
        <TranslateButton />
        <WatteryLogo className="wattery-logo" />

        <Col>
          <h4>{t('components.startTransaction.header.title')}</h4>
        </Col>

        <Container
          id="component-margin"
          className="container-min-height-min-content align-left"
        >
          <br />
          <Col className="mb-3">
            {t('components.startTransaction.header.cpNum')}
          </Col>

          <Row className="mb-3">
            <CpIdInput
              fieldLength={7}
              segments={[1, 5, 1]}
              backingField={cpId}
              updateBackingField={setCpId}
              validate={validateCp}
              disabled={wait || backgroundTasks}
              fetched={fetched}
            />
          </Row>
          <Row className="mb-3">
            {searching ? (
              <Button variant="outline-ligth">
                <img
                  src={refreshIconAnimated}
                  alt="Loading"
                  style={{ width: 30 }}
                />
              </Button>
            ) : null}
            <Col xs="auto" sm="auto" className="price-col">
              {selectedChargepoint ? (
                <>
                  {selectedChargepoint.address ? (
                    <>
                      <Form.Text>{`${t(
                        'components.startTransaction.address'
                      )}: ${selectedChargepoint.address}`}</Form.Text>
                      <br />
                    </>
                  ) : null}
                  <Form.Text>
                    {`${t('components.activeTransaction.chargepoint')}: ${
                      selectedChargepoint.nickname
                    }`}
                  </Form.Text>
                  <br />
                  <Form.Text>{`${t('components.startTransaction.seller')}: ${
                    selectedChargepoint.sellerName
                  } (${selectedChargepoint.businessId})`}</Form.Text>
                  <br />
                  <Form.Text>
                    {formatChargepointPrice(
                      selectedChargepoint,
                      t('components.startTransaction.pricing.header.price'),
                      t('components.startTransaction.serviceFee', {
                        serviceFee: toLocalePrice(
                          getServiceFeeAmount(selectedChargepoint.currency)
                        ),
                        currency: CurrencyMap[selectedChargepoint.currency],
                      }),
                      t('components.startTransaction.pricing.startPrice', {
                        startPrice: toLocalePrice(
                          selectedChargepoint.publicStartPrice
                        ),
                        currency: CurrencyMap[selectedChargepoint.currency],
                      }),
                      t('components.startTransaction.pricing.price', {
                        price: toLocalePrice(selectedChargepoint.publicPrice),
                        currency: CurrencyMap[selectedChargepoint.currency],
                      }),
                      t('components.startTransaction.spotPrice', {
                        currency: CurrencyMap[selectedChargepoint.currency],
                      }),
                      `${toLocalePrice(selectedChargepoint.margin, 3)} ${
                        CurrencyMap[selectedChargepoint.currency]
                      }/kWh + `
                    )}
                  </Form.Text>
                </>
              ) : null}
            </Col>

            {selectedChargepoint?.pricingType === 1 ? (
              <SpotPriceWidget
                useToggleShow
                defaultToggle={true}
                chargepointId={selectedChargepoint.chargePointId}
              />
            ) : null}
          </Row>
          {stripe ? (
            <Elements stripe={stripe} options={stripeOptions}>
              <InjectedCheckoutForm
                cpValidate={cpValidate}
                searching={searching}
                selectedChargepoint={selectedChargepoint}
                cpId={cpId}
                wait={wait}
                setWait={setWait}
                backgroundTasks={backgroundTasks}
                currency={currCurrency}
                setBackgroundTasks={setBackgroundTasks}
              />
            </Elements>
          ) : null}
          <div className="mt-3">
            <a href="https://user.wattery.io" className="mb-3">
              {t('components.startTransaction.already')}
            </a>
            <br />
          </div>
          <br />
        </Container>
      </StyledStartTransaction>
    </>
  );
};

export default StartTransaction;
