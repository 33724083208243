import styled from 'styled-components';

const StyledCpIdInput = styled.div`
  .display {
    border: 1px solid rgba(0, 0, 0, 0.2);
    width: 250%;
    height: 100%;
    display: flex;
    max-width: 8vw;
    min-width: 30px;
    line-height: 33px;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    position: relative;
    background: ${({ theme }) => theme.background};
    margin-left: 1%;
    margin-right: 1%;
    color: black;
    text-align: center;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    padding-inline: 0;
  }

  .prevent-select {
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -o-user-select: none;
  }

  .display-dropdown {
    height: 100%;
    display: flex;
    font-size: 22px;
    position: relative;
    margin-right: 1%;
    margin-left: 1%;
  }

  .rounded-corners {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }

  .display-dash {
    width: 80%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    position: relative;
    color: black;
  }

  .wrap {
    position: relative;
    display: flex;
    height: 6vh;
    min-height: 36px;
    width: 100%;
    @media (min-height: ${({ theme }) => theme.tablet}) {
      height: 5vh;
    }
    @media (min-height: ${({ theme }) => theme.laptop}) {
      height: 4vh;
    }
    @media (min-height: ${({ theme }) => theme.laptopL}) {
      height: 3vh;
    }
    @media (min-width: ${({ theme }) => theme.tablet}) {
      width: 50%;
    }
    @media (min-width: ${({ theme }) => theme.laptop}) {
      width: 35%;
    }
    @media (min-width: ${({ theme }) => theme.laptopL}) {
      width: 30%;
    }
  }

  .shadows {
    position: relative;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    box-shadow: 1px 1px 10px 2px rgba(100, 151, 150, 0.15);
  }
`;

export default StyledCpIdInput;
