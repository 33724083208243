import styled from "styled-components";

/**
 * Styled component for the terms of service page
 */
export const StyledActiveTransactions = styled.div`
  justify-content: left;
  justify-items: left;
  align-items: left;
  text-align: left;
  overflow-y: hidden;
  .active-card {
    padding: 5px 5px 5px 5px;
    background-color: #00b350;
    color: #ffffff;
    @media (min-width: ${({ theme }) => theme.laptopL}) {
      width: 40%;
    }
  }

  .active-btn-stop {
    width: 100px;
    margin-bottom: 10px;
  }

  .logo-container {
    @media (min-width: ${({ theme }) => theme.mobileS}) {
      text-align: center;
    }
    @media (min-width: ${({ theme }) => theme.laptopL}) {
      text-align: left;
    }
  }

  .interact-btn-container {
    text-align: center;
  }

  .active-card-header-name {
    text-align: center;
    font-size: 1rem;
    padding: 0 20px 0 20px;
    marginbottom: 7px;
  }

  .active-card-header-values {
    display: flex;
    justify-content: space-between;
    padding: 0 55px 0 30px;
    margin-top: 0;
    height: 25px;
  }

  .active-card-header {
    background-color: #00b350;
    color: #ffffff;
    text-align: center;
  }

  .active-card-body {
    background-color: #00b350;
    color: #ffffff;
    font-size: 1rem;
    text-align: left;
  }

  .spinner {
    animation-name: spin;
    animation-duration: 500ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    width: 30;
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;
