import { Contract } from '../Model/Contract';
import { WatteryResponse, WatteryResponseType } from '../Model/Option';
import { Order } from '../Model/Order';
import axios from 'axios';

export const addOrderAndContract = async (data: {
  order: Order;
  contract: Contract;
}): Promise<WatteryResponse<[number, number]>> => {
  try {
    const response = await axios.post<WatteryResponseType<[number, number]>>(
      `/api/v1/order/new`,
      data
    );
    return new WatteryResponse(response.data, true);
  } catch (e: any) {
    return new WatteryResponse(e.response.data, false);
  }
};
