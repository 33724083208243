import { CSSProperties } from 'react';
import logo from '../../resources/images/WatteryLogoGreen.svg';

declare interface WatteryLogoProps {
  className: string;
  style?: CSSProperties;
}

export default function WatteryLogo(props: WatteryLogoProps) {
  return (
    <div style={props.style}>
      <a href="https://www.wattery.io">
        <img src={logo} alt="logo" className={props.className} />
      </a>
    </div>
  );
}
