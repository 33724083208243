import { Button, FormControl, InputGroup } from 'react-bootstrap';
import { emailRegex } from '../../Utilities/Regex';
import { useEffect } from 'react';
import { StateHandler } from '../../Utilities/Types';
import { WatteryBaseFieldHeader } from './ValidatableField';

declare interface DLFProps {
  header: string;
  array: any[];
  setArray: StateHandler<any[]>;
}

export function DynamicListForm({ header, array, setArray }: DLFProps) {
  useEffect(() => {
    if (array.length === 0) {
      setArray(['']);
    }
    //eslint-disable-next-line
  }, []);
  function addField(): void {
    setArray([...array, '']);
  }

  useEffect(() => {
    if (array.length > 1)
      document.getElementById(`dynamic-list-${array.length - 1}`)?.focus();
  }, [array]);

  const handleFormChange = (index: number, event) => {
    let data = [...array];
    data[index] = event.target.value;
    setArray(data);
  };

  return (
    <>
      <WatteryBaseFieldHeader header={header} />
      <div className="mb-3">
        {array.map((a, i) => {
          return (
            <InputGroup
              style={{ left: '2%', maxWidth: '98%' }}
              key={i}
              className="mb-3"
            >
              {i === array.length - 1 ? (
                <Button
                  onClick={() => addField()}
                  disabled={!emailRegex.test(a)}
                >
                  +
                </Button>
              ) : (
                <Button disabled={true}>{i + 1}.</Button>
              )}
              <FormControl
                type="text"
                id={`dynamic-list-${i}`}
                value={a}
                onChange={(event) => handleFormChange(i, event)}
                onKeyDown={(event) => {
                  if (
                    event.key === 'Enter' &&
                    i === array.length - 1 &&
                    emailRegex.test(a)
                  ) {
                    addField();
                  }
                }}
              />
            </InputGroup>
          );
        })}
      </div>
    </>
  );
}
