import styled from 'styled-components';

/**
 * Styled component for the terms of service page
 */
export const StyledWatteryOrderForm = styled.div`
  display: flex;
  justify-content: flex-end;

  flex-flow: row wrap;
  background: ${({ theme }) => theme.background};
  color: ${({ theme }) => theme.text};
  font-size: 1rem;

  .collaborator-logo {
    max-width: 20%;
  }

  .wattery-form-body {
    text-align: left;
  }

  .hr {
    color: ${({ theme }) => theme.mediumGreen};
    height: 3px;
    background-color: ${({ theme }) => theme.mediumGreen};
    border-color: ${({ theme }) => theme.mediumGreen};
  }

  .form-check-input:checked {
    border-color: ${({ theme }) => theme.lightGreen};
    background-color: ${({ theme }) => theme.lightGreen};
  }

  .row {
    width: 90vw;
  }
  @media only screen and (min-width: 1000px) {
    width: 50vw;
  }

  [data-lastpass-icon-root],
  [data-lastpass-root] {
    display: none !important;
  }
`;
