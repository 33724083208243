import styled from 'styled-components';
import watteryLogo from '../../resources/images/WatteryLogoGreen.svg';
import { Button, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';
import { useNavigate, useLocation } from 'react-router';
import { useEffect } from 'react';

const StyledThankYou = styled.div`
  display: flex;
  justify-content: left;
  justify-items: left;
  justify-self: left;
  text-align: left;
  background: ${({ theme }) => theme.background};
  color: ${({ theme }) => theme.text};
  font-size: 1rem;
  overflow-x: auto!;
`;

export default function ThankYou() {
  const { t } = useTranslation('common', { i18n: i18n });
  const navigate = useNavigate();
  const loc = useLocation();

  useEffect(() => {
    if (loc.state !== 'new-order')
      navigate('..', { relative: 'path', state: null });
    //eslint-disable-next-line
  }, []);
  return (
    <>
      <StyledThankYou className="top-level-component">
        {loc.state !== 'new-order' ? (
          <></>
        ) : (
          <Col>
            <img
              className="mb-3"
              src={watteryLogo}
              alt="Wattery"
              style={{ maxWidth: '50%' }}
            />
            <br />
            <br />
            <h5 className="mb-3">{t('components.thankYou.text')}</h5>
            <br />
            <h5 className="mb-3">{t('components.thankYou.regards')},</h5>
            <h5 className="mb-3">{t('components.thankYou.watteryTeam')}</h5>
            <br />
            <div style={{ bottom: '10%' }}>
              <Button
                onClick={() => {
                  navigate('..', { relative: 'path' });
                }}
              >
                {t('components.thankYou.new')}
              </Button>
            </div>
          </Col>
        )}
      </StyledThankYou>
    </>
  );
}
