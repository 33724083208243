import { useEffect, useState } from 'react';
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';

import i18n from '../../i18n';
import { useTranslation } from 'react-i18next';

import { useWindowDimensions } from '../../Utilities/Hooks';

import { getCpSpotprice } from '../../Services/Transactions';
import { SpotPrice } from '../../Model/Transaction';
import { Form } from 'react-bootstrap';
import { StyledSpotPriceWidget } from './SpotPriceWidget.styled';

const SMALL_SCREEN_BREAKPOINT = 500;

const tickFormatterY = (tickValue: number) => {
  return Number(tickValue).toFixed(1).concat('¢/kWh');
};

export const SpotPriceWidget = ({
  chargepointId,
  useToggleShow,
  defaultToggle,
}: {
  chargepointId?: string;
  useToggleShow?: boolean;
  defaultToggle?: boolean;
}) => {
  const { t } = useTranslation('common', {
    i18n: i18n,
  });

  const [spotPrices, setSpotPrices] = useState<SpotPrice[]>([]);
  const [maxSpotPriceLength, setMaxSpotPriceLength] = useState(0);

  const [toggle, setToggle] = useState(defaultToggle);

  const { windowWidth } = useWindowDimensions();

  useEffect(() => {
    const getData = async () => {
      if (!chargepointId) {
        return;
      }
      const spotPriceRes = await getCpSpotprice(chargepointId);

      if (spotPriceRes.success) {
        const temp = spotPriceRes.onSuccess();
        setSpotPrices(temp);
        setMaxSpotPriceLength(temp[0].price.toFixed(1).length);
      }
    };

    getData();
  }, [chargepointId]);

  const tickFormatterX = (tickValue: any) => {
    return String(new Date(tickValue).getHours()).padStart(2, '0');
  };

  const labelFormatter = (label: any) => {
    return windowWidth < SMALL_SCREEN_BREAKPOINT
      ? ''
      : Number(label).toFixed(2) + '¢';
  };

  const sliceSize = windowWidth < SMALL_SCREEN_BREAKPOINT ? 10 : 13; // Includes one hour back

  function computeMinimumWidth() {
    const minSliceSize = Math.min(sliceSize, spotPrices.length);
    const ret = Math.min((minSliceSize * 100) / sliceSize, 100);
    return `${ret}%`;
  }

  function widget() {
    return (
      <>
        <ResponsiveContainer width={computeMinimumWidth()} height={270}>
          <BarChart
            data={spotPrices.slice(0, sliceSize)}
            margin={{
              top: 20,
              right: 0,
              bottom: 0,
              //Account for price > 100c/kWh
              left: maxSpotPriceLength * 10,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis tickFormatter={tickFormatterX} dataKey="timestamp" />
            <YAxis
              tickFormatter={tickFormatterY}
              domain={['auto', (dataMax: number) => Math.max(dataMax, 10)]}
            />
            {windowWidth < SMALL_SCREEN_BREAKPOINT ? (
              <Tooltip content={<CustomTooltip />} />
            ) : null}
            <Bar
              dataKey="price"
              fill="orange"
              label={{
                position: 'top',
                fontSize: 11,
                formatter: labelFormatter,
              }}
            >
              {spotPrices.map((_, index) => (
                <Cell key={index} stroke={index === 0 ? 'black' : 'none'} />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>

        <div className="text-center">
          <Form.Text className="text-center">
            {t('components.startTransaction.spotPrices.tooltip')}{' '}
            <a
              href="https://data.nordpoolgroup.com/auction/day-ahead/prices?deliveryAreas=FI,SE1,SE2,SE3,SE4&currency=EUR&aggregation=Hourly"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t('components.startTransaction.spotPrices.linkText')}.
            </a>
          </Form.Text>
        </div>
      </>
    );
  }

  return (
    <StyledSpotPriceWidget>
      {useToggleShow ? (
        <>
          {toggle ? (
            <>
              <br />

              <button
                className="empty-button"
                onClick={() => {
                  setToggle(!toggle);
                }}
                onMouseDown={(event) => event.preventDefault()}
              >
                <div style={{ stroke: '#666', color: '#3B3C43' }}>
                  {t('components.startTransaction.spotPrices.header')}
                  <svg
                    style={{ marginLeft: '0.3em' }}
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="10"
                    height="10"
                  >
                    <polygon points="5,10 10,0 0,0" />
                  </svg>
                </div>
                {/* <i className="arrow down" /> */}
              </button>
              {widget()}
            </>
          ) : (
            <>
              <br />

              <button
                className="empty-button"
                onClick={() => {
                  setToggle(!toggle);
                }}
                onMouseDown={(event) => event.preventDefault()}
              >
                <div style={{ stroke: '#666', color: '#3B3C43' }}>
                  {t('components.startTransaction.spotPrices.header')}
                  <svg
                    style={{ marginLeft: '0.3em' }}
                    xmlns="http://www.w3.org/2000/svg"
                    version="1.1"
                    width="10"
                    height="10"
                  >
                    <polygon points="10,5 0,10 0,0" />
                  </svg>
                </div>
                {/* <i className="arrow right" /> */}
              </button>
            </>
          )}
        </>
      ) : (
        <>
          <br />
          <text>{t('components.startTransaction.spotPrices.header')}</text>
          {widget()}
        </>
      )}
    </StyledSpotPriceWidget>
  );
};

declare interface TooltipProps {
  active?: boolean;
  payload?: any;
  label?: any;
}

const CustomTooltip = ({ active, payload }: TooltipProps) => {
  if (
    active &&
    payload &&
    payload.length &&
    typeof payload[0].value === 'number'
  ) {
    return <p>{tickFormatterY(payload[0].value)}</p>;
  }
  return null;
};
