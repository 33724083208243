import { useEffect, useState } from 'react';
import StyledCpIdInput from './CpIdInput.styled';
import React from 'react';
import {
  CountryCodes,
  FormAction,
  FormValidityAction,
} from '../../Utilities/Types';
import { CpNumber } from './StartTransaction';
import { Dropdown, DropdownButton } from 'react-bootstrap';

declare interface CpIdInputProps {
  onComplete?: (input: string) => any;
  backingField: CpNumber;
  updateBackingField: React.Dispatch<FormAction<CpNumber>>;
  segments: number[];
  fieldLength: number;
  delimiter?: string;
  validate: React.Dispatch<FormValidityAction<CpNumber>>;
  disabled?: boolean;
  fetched?: boolean;
}

const isDigit = new RegExp(/^\d+$/);
const isLetter = new RegExp(/[a-zA-Z]/);

//What a mess
export const CpIdInput = ({
  segments,
  fieldLength,
  delimiter,
  backingField,
  updateBackingField,
  validate,
  disabled,
  fetched,
}: CpIdInputProps) => {
  const [values, setValues] = useState<string[]>([]);
  const [focusedIndex, setFocusedIndex] = useState(-1);
  const [internalDelimiter] = useState(delimiter ? delimiter : '-');
  const [delims] = useState(() => {
    const indices: number[] = [];
    let runningIndex = 0;
    for (let i = 0; i < segments.length - 1; i++) {
      runningIndex += segments[i];
      indices.push(runningIndex);
      ++runningIndex;
    }

    return {
      amount: segments.length - 1,
      indices: indices,
    };
  });

  useEffect(() => {
    const len = fieldLength + segments.length - 1;
    const totalLen = new Array(len).fill('');
    const temp = populateCpIdField(totalLen);
    setFocusedIndex(temp.findIndex((v) => v === ''));
    setValues(temp);
    //eslint-disable-next-line
  }, []);

  function populateCpIdField(emptyArray: string[]) {
    return emptyArray.map((v, index) => {
      if (delims.indices.includes(index)) {
        return internalDelimiter;
      } else {
        const bf = resolveBackingField(index);
        if (bf !== undefined) return bf;
        return v;
      }
    });
  }

  function resolveBackingField(index: number) {
    const [bfIdx, bfKey] = getBackingFieldIndex(index);
    return backingField[bfKey].length > 0
      ? backingField[bfKey][bfIdx]
      : undefined;
  }

  function getBackingFieldIndex(index: number): [number, keyof CpNumber] {
    const preIdx = 0;
    const bIdx = preIdx + segments[0] + 1;
    const postIdx = bIdx + segments[1] + 1;
    if (index < bIdx) {
      return [index, 'countryCode'];
    }
    if (index < postIdx) {
      return [index - bIdx, 'chargepointNumber'];
    }
    return [index - postIdx, 'connectorId'];
  }

  useEffect(() => {
    validate({
      type: 'chargepointNumber',
      payload: backingField,
      validator: () => backingField.chargepointNumber.length === 5,
    });
    validate({
      type: 'connectorId',
      payload: backingField,
      validator: () => backingField.connectorId.length === 1,
    });
    //eslint-disable-next-line
  }, [backingField]);

  //UseEffect for displaying a chargepoint number fetched from the server using its charge_point_id
  //Is run when .../id/{charge_point_id} is present in the URL
  useEffect(() => {
    //Check if backingField is populated already
    if (
      backingField.chargepointNumber.length === 5 &&
      backingField.connectorId.length === 1 &&
      fetched
    ) {
      const temp = populateCpIdField(values);
      setValues(temp);
      //After populating the display, blur the input elements if they are in focus
      if (document.activeElement instanceof HTMLElement)
        document.activeElement.blur();
    }
    //eslint-disable-next-line
  }, [backingField, fetched]);

  function targetNext(e: React.ChangeEvent<HTMLInputElement>) {
    const maybeSibling = e.target.nextElementSibling as HTMLInputElement | null;

    if (maybeSibling?.className === 'display-dash') {
      const nextSibling = maybeSibling.nextElementSibling as HTMLInputElement;
      return nextSibling;
    }
    return maybeSibling;
  }

  function targetPrevious(
    e: React.KeyboardEvent<HTMLInputElement>
  ): HTMLInputElement | null {
    const target = e.target as HTMLInputElement;
    const maybeSibling =
      target.previousElementSibling as HTMLInputElement | null;

    if (maybeSibling?.className === 'display-dash') {
      const prevSibling =
        maybeSibling.previousElementSibling as HTMLInputElement;
      return prevSibling;
    }
    return maybeSibling;
  }

  function targetNextArrow(
    e: React.KeyboardEvent<HTMLInputElement>
  ): HTMLInputElement | null {
    const target = e.target as HTMLInputElement;
    const maybeSibling = target.nextElementSibling as HTMLInputElement | null;

    if (maybeSibling?.className === 'display-dash') {
      const nextSibling = maybeSibling.nextElementSibling as HTMLInputElement;
      return nextSibling;
    }
    return maybeSibling;
  }

  //Band-aid for handling invalid connector id
  //Don't need the extra validation provided by this component since we know what
  //can change based on the result of the request
  useEffect(() => {
    if (values[values.length - 1] !== backingField.connectorId) {
      values[values.length - 1] = backingField.connectorId;
    }
    //eslint-disable-next-line
  }, [backingField]);

  function setValueAt(value: string, index: number) {
    const temp = values.map((a, idx) => {
      if (idx === index) return value;
      return a;
    });
    setValues(temp);
    const preIdx = segments[0];
    const bIdx = preIdx + segments[1];
    if (index < preIdx) {
      updateBackingField({ type: 'countryCode', payload: value });
      return;
    }
    if (index > bIdx) {
      updateBackingField({ type: 'connectorId', payload: value });
      return;
    }
    const cpNum = temp.slice(2, 7).join('');
    updateBackingField({ type: 'chargepointNumber', payload: cpNum });
  }

  const inputOnChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    idx: number
  ) => {
    const target = e.target as HTMLInputElement;
    let targetValue = target.value;
    const isTargetValueDigit = isDigit.test(targetValue);

    if (!isTargetValueDigit && idx > 2 && targetValue !== '') {
      return;
    }

    targetValue = isTargetValueDigit ? targetValue : '';

    setValueAt(targetValue, idx);
    if (targetValue === '') return;
    const nextSibling = targetNext(e);
    if (nextSibling !== null) return nextSibling.focus();
  };

  const inputOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const target = e.target as HTMLInputElement;
    if (e.key === 'Backspace' && target.value === '') {
      const previousElementSibling = targetPrevious(e);

      if (previousElementSibling !== null) previousElementSibling.focus();

      target.setSelectionRange(0, target.value.length);
      return;
    }

    if (e.key === 'ArrowLeft') {
      const previousElementSibling = targetPrevious(e);

      if (previousElementSibling !== null) {
        previousElementSibling.selectionStart =
          previousElementSibling.selectionEnd = 10000;
        previousElementSibling.focus();
      }
    }

    if (e.key === 'ArrowRight') {
      const nextSibling = targetNextArrow(e);
      if (nextSibling !== null) {
        nextSibling.selectionStart = nextSibling.selectionEnd = 10000;
        nextSibling.focus();
      }
    }
  };

  return (
    <StyledCpIdInput>
      <div className="wrap prevent-select">
        {values.map((v, index) => {
          return v === '-' ? (
            <div
              className="display-dash"
              key={index}
            >{` ${internalDelimiter} `}</div>
          ) : index < segments[0] ? (
            <DropdownButton
              disabled={disabled}
              key={'dropdown'}
              className="display-dropdown shadows rounded-corners"
              title={backingField.countryCode}
              data-cy="country-code-selector"
              id="country-code-selector"
              onMouseDown={(event) => event.preventDefault()}
            >
              {Object.entries(CountryCodes).map(([_, v], idx) => {
                return (
                  <Dropdown.Item
                    as="button"
                    key={'dropdown' + idx}
                    onClick={() =>
                      updateBackingField({
                        type: 'countryCode',
                        payload: v,
                      })
                    }
                  >
                    {v}
                  </Dropdown.Item>
                );
              })}
            </DropdownButton>
          ) : (
            <SingleCellInput
              index={index}
              isFocused={focusedIndex === index}
              v={v}
              onChange={inputOnChange}
              onKeyDown={inputOnKeyDown}
              key={index}
              disabled={disabled}
            />
          );
        })}
      </div>
    </StyledCpIdInput>
  );
};

declare interface SingleCellInputProps {
  index: number;
  v: string;
  onChange: Function;
  onKeyDown: Function;
  isFocused: boolean;
  disabled?: boolean;
}

function SingleCellInput({
  index,
  v,
  onChange,
  onKeyDown,
  isFocused,
  disabled,
}: SingleCellInputProps) {
  function disableSelection(event: React.BaseSyntheticEvent) {
    setTimeout(function () {
      event.target.selectionStart = event.target.selectionEnd = 10000;
    }, 0);
  }
  return (
    <input
      autoFocus={isFocused}
      className="display shadows rounded-corners prevent-select"
      type="text"
      inputMode="numeric"
      pattern="\d{1}"
      disabled={(isLetter.test(v) && index > 2) || disabled}
      maxLength={1}
      onFocus={(e) => {
        disableSelection(e);
      }}
      onClick={(e) => disableSelection(e)}
      onMouseDown={(e) => disableSelection(e)}
      onChange={(event) => {
        onChange(event, index);
      }}
      onKeyDown={(event) => onKeyDown(event)}
      onBlur={() => {}}
      value={v}
    />
  );
}
