export class WatteryResponse<T> {
  value: WatteryResponseType<T>;
  success: boolean;

  constructor(value: WatteryResponseType<T>, success: boolean) {
    this.value = value;
    this.success = success;
  }

  public onSuccess(): T {
    return this.value.data!;
  }

  public onError(): WatteryResponseType<T> {
    return {
      data: this.value.data,
      error: this.value.error,
      resultType: this.value.resultType,
    } as const;
  }
}

export declare interface WatteryResponseType<T> {
  data?: T;
  error: string;
  resultType: keyof [WatteryResultType];
}

export const WatteryResult = {
  Ok: 10,
  Invalid: 20,
  Unauthorized: 30,
  PartialOk: 40,
  NotFound: 50,
  PermissionDenied: 60,
  Unexpected: 70,
} as const;

type WatteryResultType = typeof WatteryResult;
