import { InterpolationMap, TFunction } from 'i18next';
import { CardInfo, Card, CardType, SubTransaction } from '../Model/Receipt';
import { AlertObj } from '../components/General/PopupAlert';
import { CurrencyMap } from './CurrencyMap';
import { EV_CHARGING_UNIT } from './Types';
import { ToastOptions, toast } from 'react-toastify';

export function deactivateAlerts(...alerts: AlertObj[]) {
  for (const a of alerts) {
    a.deactivate();
  }
}

export function dismissToasts() {
  toast.dismiss();
}

export function toLocalePrice(price: number, decimals: number = 2) {
  return Math.max(price, 0).toFixed(decimals).replace('.', ',');
}

export function toLocalePriceCurrency(
  price: number,
  currency: string,
  decimals: number = 2
) {
  const currencySymbol = CurrencyMap[currency];
  return toLocalePrice(price, decimals).concat(` ${currencySymbol}`);
}

export function toLocalePriceCurrencyPerChargingUnit(
  price: number,
  currency: string,
  decimals: number = 2
) {
  return (
    toLocalePrice(price, decimals) + currencyPerChargingUnit(currency, true)
  );
}

export function currencyPerChargingUnit(
  currency: string,
  spaceBefore?: boolean
) {
  const currencySymbol = CurrencyMap[currency];
  return spaceBefore
    ? ` ${currencySymbol}/${EV_CHARGING_UNIT}`
    : `${currencySymbol}/${EV_CHARGING_UNIT}`;
}

export function formatSubTransactionUnitPriceWithVat(
  subTransaction: SubTransaction
): string {
  const unitPriceVat = WithVat(
    subTransaction.price.unitPrice,
    subTransaction.price.vatRate
  );
  const margin = Number(subTransaction.price.margin);

  if (margin > 0) {
    const formattedMargin = toLocalePriceCurrencyPerChargingUnit(
      margin,
      subTransaction.price.currency,
      4
    );
    const formattedUnitPriceVat = toLocalePrice(unitPriceVat, 4);
    return formattedUnitPriceVat.concat('+').concat(formattedMargin);
  }
  //Don't add margin to receipt price calculation if it's zero
  const formattedUnitPriceVat = toLocalePriceCurrencyPerChargingUnit(
    unitPriceVat,
    subTransaction.price.currency,
    4
  );
  return formattedUnitPriceVat;
}

export function formatSubTransaction(
  subTransaction: SubTransaction
): [string, string] {
  const formattedUnitPrice =
    formatSubTransactionUnitPriceWithVat(subTransaction);

  const calculatedSubTransactionPrice = toLocalePrice(
    Number(subTransaction.intervalPrice),
    4
  );
  return [formattedUnitPrice, calculatedSubTransactionPrice];
}

export function formatCardIssuer(card?: CardInfo): CardType | null {
  return card?.brand !== undefined && card.brand !== 'unknown'
    ? Card[card.brand]
    : null;
}

export function getVatAmount(price: number, vat: number): number {
  return price - price / (1 + vat * 0.01);
}

export function WithVat(price: number, vat: number) {
  return price * (1 + vat * 0.01);
}

export function getServiceFeeAmount(currency: string) {
  switch (currency) {
    case 'EUR':
      return 0.5;
    case 'eur':
      return 0.5;
    case 'SEK':
      return 3;
    case 'sek':
      return 3;
    default:
      return 0.5;
  }
}
export function resolveStripeAmount(currency: string): number {
  switch (currency) {
    default:
      return 2500;
    case 'eur':
      return 2500;
    case 'EUR':
      return 2500;
    case 'sek':
      return 25_000;
    case 'SEK':
      return 25_000;
  }
}

export function resolveDisplayReservation(currency: string): number {
  switch (currency) {
    default:
      return 25;
    case 'eur':
      return 25;
    case 'EUR':
      return 25;
    case 'sek':
      return 250;
    case 'SEK':
      return 250;
  }
}

export function showToast(
  t: TFunction,
  type: 'error' | 'success' | 'warning',
  relativePath: string,
  interpolationData?: InterpolationMap<string>,
  toastOptions?: ToastOptions
) {
  const a = () => {
    switch (type) {
      case 'error':
        return 'global.alert.failure.';
      case 'success':
        return 'global.alert.success.';
      case 'warning':
        return 'global.alert.warning.';
      default:
        return '';
    }
  };

  const id = toast(t(`${a()}${relativePath}`, interpolationData), {
    type: type,
    toastId: relativePath,
    ...toastOptions,
  });
  return { dismiss: () => toast.dismiss(id) };
}
