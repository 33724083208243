import { Dispatch } from 'react';
import { Order } from '../../Model/Order';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n';
import {
  CountryCodes,
  FormAction,
  FormValidityAction,
  FormValidityState,
  InvoiceChannel,
  Key,
} from '../../Utilities/Types';
import { OrderValidity } from './WatteryOrderForm';
import {
  DefaultField,
  DropdownField,
  ValidatableField,
  WatteryBaseFieldHeader,
} from '../General/ValidatableField';
import { emailRegex } from '../../Utilities/Regex';

declare interface WOFOProps {
  order: Order;
  setOrder: Dispatch<FormAction<Order>>;
  oValidity: FormValidityState<OrderValidity>;
  setOValidity: Dispatch<FormValidityAction<Order>>;
}

const WatteryOrderFormOrder = ({
  order,
  setOrder,
  oValidity,
  setOValidity,
}: WOFOProps) => {
  const { t } = useTranslation('common', { i18n: i18n });
  function ValidateEmail(email: string) {
    return emailRegex.test(email);
  }

  return (
    <>
      <ValidatableField
        header={t('components.orderForm.form.order.name')}
        state={order}
        setState={setOrder}
        validity={oValidity}
        setValidity={setOValidity}
        field={'CompanyName'}
      />
      <ValidatableField
        header={t('components.orderForm.form.shared.businessId')}
        state={order}
        setState={setOrder}
        validity={oValidity}
        setValidity={setOValidity}
        field={'BusinessId'}
      />
      <ValidatableField
        header={t('components.orderForm.form.shared.saddress2')}
        state={order}
        setState={setOrder}
        validity={oValidity}
        setValidity={setOValidity}
        field="StreetAddress"
      />
      <DefaultField
        header={t('components.orderForm.form.shared.saddress1')}
        state={order}
        setState={setOrder}
        field="AddressSpecifier"
      />
      <ValidatableField
        header={t('components.orderForm.form.shared.zip')}
        state={order}
        setState={setOrder}
        validity={oValidity}
        setValidity={setOValidity}
        field="ZipCode"
      />
      <ValidatableField
        header={t('components.orderForm.form.shared.city')}
        state={order}
        setState={setOrder}
        validity={oValidity}
        setValidity={setOValidity}
        field="City"
      />
      <DropdownField
        header={t('components.orderForm.form.shared.country')}
        state={order}
        setState={setOrder}
        field="Country"
        template={CountryCodes}
      />
      {/* <ValidatableField
        header={t('components.orderForm.form.shared.country')}
        state={order}
        setState={setOrder}
        validity={oValidity}
        setValidity={setOValidity}
        field="Country"
      /> */}
      <WatteryBaseFieldHeader
        header={t('enumerations.invoiceChannel.enumName')}
      />
      <div className="mb-3">
        <DropdownButton
          title={t(
            `enumerations.invoiceChannel.${InvoiceChannel[
              order.InvoiceChannel
            ]!}`
          )}
        >
          <>
            {Object.entries(InvoiceChannel).map(([k, v], idx) => {
              if (v === 'none' || v === 'mail') return null;
              return (
                <Dropdown.Item
                  as="button"
                  key={idx}
                  onClick={() => {
                    setOrder({
                      type: 'InvoiceChannel',
                      payload: Number(k) as Key<typeof InvoiceChannel>,
                    });
                  }}
                >
                  {t(`enumerations.invoiceChannel.${v}`)}
                </Dropdown.Item>
              );
            })}
          </>
        </DropdownButton>
      </div>
      {order.InvoiceChannel === 3 ? (
        <>
          <ValidatableField
            header={t('components.orderForm.form.contract.operator')}
            state={order}
            setState={setOrder}
            validity={oValidity}
            setValidity={setOValidity}
            field="EinvoiceOperator"
          />
          <ValidatableField
            header={t('components.orderForm.form.contract.einvoice')}
            state={order}
            setState={setOrder}
            validity={oValidity}
            setValidity={setOValidity}
            field="EinvoiceAddress"
          />
          <DefaultField
            header={t('components.orderForm.form.contract.edi')}
            state={order}
            setState={setOrder}
            field="EinvoiceEdi"
          />
        </>
      ) : order.InvoiceChannel === 1 ? (
        <>
          <ValidatableField
            header={t('components.orderForm.form.contract.invEmail')}
            state={order}
            setState={setOrder}
            validity={oValidity}
            setValidity={setOValidity}
            field="InvoiceEmail"
            validator={ValidateEmail}
          />
        </>
      ) : null}
      <DefaultField
        header={t('components.orderForm.form.order.invoiceReference')}
        state={order}
        setState={setOrder}
        field="InvoiceReference"
      />
    </>
  );
};

export default WatteryOrderFormOrder;
